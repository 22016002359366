import { isEmpty, pickBy } from "lodash";
import { useRouter } from "next/router";
import { simpleDate, unformatCurrency } from "../../../../components/tools";
import {
  getUserButtonBooleans,
  useGetAuth,
} from "../../../../contexts/AuthContext";
import { queryArray } from "../../../eWalletComponents/home/main/filter/modal/content/parent";
import { transactionsBooleans } from "./booleans";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";

export const useTransactionFilters = (page = 1, type, perPage) => {
  const {
    isMine,
    isTeam,
    isCompany,
    isPayIn,
    isPayOut,
    isFiltered,
    isStatement,
    isAccountLedger,
    isGeneralLedger,
    isEdd,
  } = transactionsBooleans(type);

  const { pathname, query } = useRouter();
  const {
    from_date,
    to_date,
    amount_type,
    specific_amount,
    minimum_amount,
    maximum_amount,
    attachment,
    runQuery,
    search_box,
    menu,
  } = query;

  const isCategoryDetailsPage = pathname === "/category/details/[id]";
  const isMyActivity = pathname === "/my-activity";
  const isTeamMenu = isMyActivity && menu === "team_transaction";
  const isFilteredMine = isFiltered && isMyActivity;
  const isFilteredTeam = isFiltered && isTeamMenu;

  const keywords = queryArray(query, "searchbox");

  const rangeDecider = () => {
    const checker = ({ gte, lte }) => {
      const obj = { gte, lte };
      const result = pickBy(obj, (value) => value);
      const empty = isEmpty(result);
      return { empty, result };
    };

    const dateDecider = () => {
      const { empty, result } = checker({
        gte: simpleDate(from_date),
        lte: simpleDate(to_date),
      });

      if (empty) return null;

      return { created_at: result };
    };

    const amountDecider = () => {
      const { empty, result } = checker({
        gte: Number(unformatCurrency(minimum_amount)),
        lte: Number(unformatCurrency(maximum_amount)),
      });

      if (empty) return null;

      return { amount_numeric: result };
    };

    const range = [dateDecider(), amountDecider()].filter((item) => item);

    if (range.length) return { range };

    return {};
  };

  const keywordsDecider = () => {
    if (keywords.length) return keywords.join(" ");
    return search_box || "";
  };

  const arrayDecider = (queryName, name) => {
    const array = queryArray(query, queryName);
    if (!array.length) return {};
    let obj = {};
    obj[name] = array;
    return obj;
  };

  const categoryDecider = () => {
    const queries = queryArray(query, "product");

    const category = queries
      .map((value) => {
        if (value == "cross_border") return ["single_cross_border"];
        if (value == "local") return ["local_single", "local_batch", "API"];
        if (value == "card") return ["card_payment", "card_transaction"];
        if (value == "top_up")
          return ["incoming_payment", "topup_transaction", "topup_request"];
        return value;
      })
      .flat(1);

    if (!category.length) return {};

    return { category };
  };

  const { user } = useGetAuth();
  const { users } = useConstants();
  const { id: user_id, managed_teams } = user || {};

  const managedTeamIds = (managed_teams ?? []).map(({ id }) => id);

  const managedUsers = (users ?? []).filter(({ team_id }) =>
    managedTeamIds.includes(team_id)
  );
  const managedUserIds = managedUsers.map(({ id }) => id);

  const status = arrayDecider("status", "status");
  const source_account = arrayDecider("source_account", "source_account");
  const card = arrayDecider("card", "details.user_card_id");
  const createdBy =
    isMine || isFilteredMine
      ? { user_id: [user_id] }
      : arrayDecider("created_by", "user_id");
  const categories = isCategoryDetailsPage
    ? { category_id: [query.id] }
    : arrayDecider("category", "category_id");
  const team =
    isTeam || isFilteredTeam
      ? { user_id: managedUserIds }
      : arrayDecider("team", "team_id");

  const eWalletUser = () => {
    const array = queryArray(query, "e_wallet_user");

    if (!array.length) return {};
    return {
      source_account: ["e_wallet"],
      user_id: array,
      category_not_eq: [],
    };
  };

  const amountButtons = () => {
    if (!amount_type) return {};
    const is_debit = amount_type == "money_in";
    return { is_debit };
  };

  const attachmentButtons = () => {
    if (!attachment) return {};
    const has_attachment = attachment == "has_attachment";
    return { has_attachment };
  };

  const amountSpecificDecider = () => {
    if (!specific_amount) return {};
    return { amount_numeric: Number(unformatCurrency(specific_amount)) };
  };

  // useEffect(() => {
  //   if (isFirstRender) return setIsFirstRender(false);
  //   runsQuery();
  // }, [!!ids]);

  // const idsDecider = () => {
  //   const isIds = Array.isArray(ids);
  //   if (!isIds) return {};
  //   return { id: ids };
  // };
  const per_page = perPage || 20;
  const { canViewCards } = getUserButtonBooleans();

  const eddFilters = isEdd
    ? {
        category: ["single_cross_border"],
        "details.need_edd": true,
        "details.edd_document_status": "created",
      }
    : {};

  const initialPayload = {
    keyword: keywordsDecider(),
    filters: {
      page,
      per_page,
      category_not_eq: [
        "local_single",
        "business_billing_statement",
        "allocation_request",
        "return_request",
        "wallet_return",
        "wallet_allocation",
        "wallet_allocation_refund",
        "incoming_payment",
      ],
      status_not_eq: ["draft"],
      ...card,
      ...status,
      ...source_account,
      ...createdBy,
      ...categories,
      ...team,
      ...categoryDecider(),
      // ...idsDecider(),
      ...eWalletUser(),
      ...rangeDecider(),
      ...amountButtons(),
      ...amountSpecificDecider(),
      ...attachmentButtons(),
      ...eddFilters,
      // "originator_type.keyword_not_eq": ["Hub::VaTransaction"],
    },
  };

  if (!canViewCards) {
    initialPayload.filters.category_not_eq.push(
      "card_topup_payment",
      "card_move_balance",
      "card_transaction",
      "card_payment"
    );
  }

  const payload = initialPayload;

  return { payload, runQuery };
};
