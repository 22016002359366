import { Fragment } from "react";
import { booleanState } from "../../../../components/Status";
import { ancestoryLinesFormatter } from "../helpers";
import useTrxReleaser from "./useTrxReleaser";
import { customDateFormatter } from "../../../../components/tools";
import { Trans, useTranslation } from "react-i18next";
import { TextInlineMedium } from "components/Text";

const useSingleTrxUpdates = ({
  currentTrxId = null,
  batchTrx = {},
  transactions = [],
}) => {
  const { t: tRetryResubmit } = useTranslation("retry-resubmit/retry-resubmit");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { t } = useTranslation("payroll/create");
  const { releaser, releasedAt } = useTrxReleaser({ trx: batchTrx });
  const releaserName = releaser?.name || releaser?.email || "-";

  const ancestoryLines = ancestoryLinesFormatter(transactions);

  const currentTrxAncestorLines = Object.values(ancestoryLines)?.filter(
    (ancestoryLine) => {
      return ancestoryLine.find(({ id: trxId }) => trxId === currentTrxId);
    }
  );
  const longestTrxCurrentAncestorLineLength = currentTrxAncestorLines.reduce(
    (longestCurrentTrxAncestorLineLength, currentAncestorLine) => {
      if (longestCurrentTrxAncestorLineLength < currentAncestorLine.length)
        return currentAncestorLine.length;
      return longestCurrentTrxAncestorLineLength;
    },
    0
  );
  const longestTrxCurrentAncestoryLine = currentTrxAncestorLines.find(
    (ancestoryLine) =>
      ancestoryLine.length === longestTrxCurrentAncestorLineLength
  );
  const ancestoryStartIndex = longestTrxCurrentAncestoryLine.findIndex(
    ({ id: trxId }) => trxId === currentTrxId
  );

  const trueAncestoryLine = longestTrxCurrentAncestoryLine.slice(
    ancestoryStartIndex + 1,
    longestTrxCurrentAncestoryLine.length
  );

  const currentTrxAncestoryLine = ancestoryLines[currentTrxId];

  const status = (state) => {
    const { isFailed, isDeclined, isCompleted } = booleanState(state);
    if (isFailed || isDeclined) return "failed";
    if (isCompleted) return "completed";
    return "active";
  };

  const content = (state) => {
    const { isFailed, isDeclined, isCompleted } = booleanState(state);
    if (isFailed || isDeclined) return t("Transaction failed");
    if (isCompleted) return t("Transaction complete!");
    return t("Sending money to recipient");
  };

  const initialUpdate = {
    status: "success",
    contents: [
      {
        type: "primary",
        content: (
          <Fragment>
            {tLocalTransfer("Payment has been released by ")}
            <span style={{ fontFamily: "GothamMedium" }}>{releaserName}</span>
          </Fragment>
        ),
      },
      {
        type: "secondary",
        content: customDateFormatter(releasedAt),
      },
    ],
  };

  if (trueAncestoryLine.length === 0) {
    const currentTrx =
      currentTrxAncestoryLine[currentTrxAncestoryLine.length - 1];
    const currentTrxState = currentTrx?.state;
    const statusPayload = status(currentTrxState);

    const showReason = statusPayload === "failed";
    const showDate = ["failed", "completed"].includes(statusPayload);

    return [
      initialUpdate,
      {
        status: statusPayload,
        contents: [
          { type: "primary", content: content(currentTrxState) },
          showReason && {
            type: "reason",
            content: t("Something's gone wrong. Contact us for details."),
          },
          showDate && {
            type: "secondary",
            content: customDateFormatter(currentTrx?.updated_at),
          },
        ],
      },
    ];
  }

  const initialFailedTrx = longestTrxCurrentAncestoryLine[ancestoryStartIndex];
  const initialFailedTrxDate = initialFailedTrx?.status_history?.find(
    ({ to }) => ["failed", "partial_failed"].includes(to)
  )?.created_at;
  const initialFailedTrxReferenceId = initialFailedTrx?.reference_id;

  const initialFailedUpdate = {
    status: "failed",
    contents: [
      {
        type: "primary",
        content: t("Transaction failed"),
      },
      {
        type: "secondary",
        content: customDateFormatter(initialFailedTrxDate),
      },
      {
        type: "reason",
        content: t("Something's gone wrong. Contact us for details."),
      },
    ],
  };

  const retryUpdates = trueAncestoryLine.map(
    ({ state, reference_id, status_history }, index) => {
      const isFirst = index === 0;
      const isLast = index === trueAncestoryLine.length - 1;

      const prevReferenceId = isFirst
        ? initialFailedTrxReferenceId
        : trueAncestoryLine[index - 1]?.reference_id;

      const failedDateIndex = status_history?.findIndex(({ to }) =>
        ["failed", "partial_failed"].includes(to)
      );
      const failedDate = status_history?.[failedDateIndex]?.created_at;
      const retriedDate =
        status_history?.[failedDateIndex + 1]?.created_at ?? new Date();
      const completedDate =
        status_history?.[status_history.length - 1]?.created_at;

      const lengthDiff =
        longestTrxCurrentAncestorLineLength - trueAncestoryLine?.length;

      const isAlreadyReachedMaxRetryAttempt = lengthDiff + index > 1;

      const retriedUpdate = {
        status: "retry",
        contents: [
          {
            type: "primary",
            content: (
              <Fragment>
                <span style={{ fontFamily: "GothamMedium" }}>
                  {releaserName}
                </span>{" "}
                <Trans
                  i18nKey={t("Dynamic.retry_prev_next", {
                    prev: prevReferenceId,
                    next: reference_id,
                  })}
                  components={[<TextInlineMedium />]}
                />
              </Fragment>
            ),
          },
          {
            type: "secondary",
            content: customDateFormatter(retriedDate),
          },
        ],
      };

      if (isLast) {
        const { isFailed, isDeclined, isCompleted } = booleanState(state);

        const showDate = isFailed || isDeclined || isCompleted;
        const showReason = isFailed || isDeclined;

        return [
          retriedUpdate,
          {
            status: status(state),
            contents: [
              {
                type: "primary",
                content: content(state),
              },
              showDate && {
                type: "secondary",
                content: customDateFormatter(
                  isFailed ? failedDate : completedDate
                ),
              },
              showReason && {
                type: "reason",
                content: isAlreadyReachedMaxRetryAttempt
                  ? "Something's gone wrong. Please your AM for further assistance."
                  : tLocalTransfer(
                      "Something's gone wrong. Contact us for details."
                    ),
              },
            ],
          },
        ];
      }

      return [
        retriedUpdate,
        {
          status: "failed",
          contents: [
            {
              type: "primary",
              content: t("Transaction failed"),
            },
            {
              type: "secondary",
              content: customDateFormatter(failedDate),
            },
            {
              type: "reason",
              content: isAlreadyReachedMaxRetryAttempt
                ? t(
                    "Something's gone wrong. Please your AM for further assistance."
                  )
                : tLocalTransfer(
                    "Something's gone wrong. Contact us for details."
                  ),
            },
          ],
        },
      ];
    }
  );

  const flattenRetryUpdates = retryUpdates.flat();

  return [initialUpdate, initialFailedUpdate, ...flattenRetryUpdates];
};

export default useSingleTrxUpdates;
