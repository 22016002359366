import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import { isID, useForm } from "@tools";
import { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { CheckBox } from "../../../components/Checkbox";
import { DateInputJackUseForm } from "../../../components/inputs/date/parent";
import { SelectionJack } from "../../../components/inputs/selection";
import { TextFieldJack } from "../../../components/inputs/textfield";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
  TextInlineRegular,
} from "../../../components/Text";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useMutation } from "../../../tools/api";
import { redStarLabel } from "../../invoiceComponents/createPage/modals/formEdit";
import { FormDecider } from "../../paymentCycleComponents/modal";
import {
  blueYellowStyle,
  days,
  generateTimeOptions,
  generateTimeOptionsComplex,
  productsMenuOptions,
  timeRange,
} from "../constant";
import {
  changeHour,
  createPCPayload,
  formatDateTimeString,
  formatScheduleString,
  getMonthlySummary,
  getNextPaymentDates,
  getYearlySummary,
  payloadMaker,
  setFormDefaultValue,
} from "../formatter";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../components/Modals/RightModal/parent";
import { ModalOption } from "./components";
import { useModalHook } from "../../../components/Modals";
import { PaymentCycleList } from ".";
import { getUserRole } from "../../../contexts/AuthContext";
import { format } from "date-fns";
import { components } from "react-select";
import { colors, jackColors } from "../../../assets/colors";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { enUS, id } from "date-fns/locale";
import { handleDate } from "../dashboardComponent/modals.js/formatter";
import { useLanguage } from "public/locales/translationFunctions";

const dummyOption = generateTimeOptions(6, 22);
const CloseButtonModal = ({ toggle }) => {
  return (
    <div
      className="hover-600"
      style={{
        width: 40,
        height: 40,
        border: "1px solid #E6E6E8",
        borderRadius: 4,
        display: "grid",
        placeItems: "center",
        cursor: "pointer",
      }}
      onClick={toggle}
    >
      <JackIcons
        name="close_Outline"
        fill="#343434"
        style={{ width: 20, height: 20 }}
      />
    </div>
  );
};

const ModalHeader = ({ title, subtitle, handleClose }) => {
  return (
    <div
      style={{
        padding: 20,
        borderBottom: "1px solid #E6E6E8",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div className="d-flex-column">
        <GothamMedium className="font20 mb-1">{title}</GothamMedium>
        <GothamRegular style={{ color: "#909098" }}>{subtitle}</GothamRegular>
      </div>
      <CloseButtonModal toggle={handleClose} />
    </div>
  );
};

const ModalButtons = ({ onClickSave, onClickCancel }) => {
  const { t } = useTranslation("schedule-payment/payment-cycle");
  return (
    <div
      style={{
        padding: 20,
        borderTop: "1px solid #E6E6E8",
        display: "flex",
        justifyContent: "end",
      }}
    >
      <div style={{ display: "flex", gap: 16 }}>
        <ButtonJack
          type="outline"
          style={{ width: 66 }}
          onClick={onClickCancel}
        >
          {t("Cancel")}
        </ButtonJack>
        <ButtonJack style={{ width: 52 }} onClick={onClickSave}>
          {t("Save")}
        </ButtonJack>
      </div>
    </div>
  );
};

export const CustomResultBanner = ({
  showResult = false,
  text,
  helperText,
}) => {
  const Text = showResult ? GothamMedium : GothamRegular;
  return (
    <div>
      <div
        style={{
          minHeight: 44,
          height: "auto",
          width: "100%",
          borderRadius: 4,
          backgroundColor: showResult ? "#F4F9FF" : "#F1F1F1",
          border: `1px solid ${showResult ? "#6087E7" : "#E6E6E8"}`,
          display: "flex",
          gap: 8,
          padding: 12,
          position: "relative",
        }}
      >
        <JackIcons
          name="calendar"
          fill={showResult ? "#343434" : "#909098"}
          style={{ width: 20, height: 20 }}
        />
        <Text
          style={{ minHeight: 20, color: showResult ? "#343434" : "#909098" }}
        >
          {text}
        </Text>
      </div>
      {helperText && (
        <GothamRegular
          className="font10"
          style={{ color: "#909098", marginTop: 4 }}
        >
          {helperText}
        </GothamRegular>
      )}
    </div>
  );
};

export const SpesificDateModal = ({
  isOpen,
  toggle,
  onSubmit: onClick,
  useFormObj,
  defaultValue,
}) => {
  const { t } = useTranslation("schedule-payment/release");
  const { handleSubmit, watch, reset, setValue, register } = useFormObj;

  const onSubmit = (val) => {
    const { time: timeRaw, date } = val;
    const date_and_time = changeHour(date, timeRaw?.value);

    return onClick(date_and_time);
  };

  const dateValue = watch("date");
  const timeValue = watch("time");
  const showResult = Boolean(dateValue) && Boolean(timeValue);

  const text = showResult
    ? formatDateTimeString(watch())
    : t("Your selected date and time will appear here");

  const handleClose = () => {
    setValue("date", null);
    setValue("time", null);
    toggle();
  };

  // useEffect(() => {
  //   if (defaultValue.type == "spesific_date") {
  //     const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  //     const { date_and_time: value } = defaultValue?.payload;
  //     const formattedTime = value.toLocaleTimeString("en-US", options);
  //     const defaultTimeValue = dummyOption
  //       ?.filter(({ value }) => value == formattedTime)
  //       ?.pop();

  //     console.log(defaultTimeValue, value, "dft");
  //     register("date");
  //     register("time");
  //     setValue("date", value);
  //     setValue("time", defaultTimeValue);
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    if (dateValue && timeValue) return setValue("time", null);
  }, [dateValue]);

  const options = generateTimeOptionsComplex(dateValue);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="my-0"
      centered
      style={{ width: 480 }}
    >
      <div>
        <ModalHeader
          title={t("Specific Date & Time")}
          subtitle={t(
            "Set the payment date and time based on the transaction’s regulation or your preference."
          )}
          handleClose={handleClose}
        />
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="d-flex justify-content-between">
            <DateInputJackUseForm
              label={redStarLabel(t("Date"))}
              style={{ width: 204 }}
              minDate={new Date()}
              useFormObj={useFormObj}
              name="date"
              placeholder={t("Select date")}
              dateFormat="d/MM/yyyy"
            />
            <SelectionJack
              label={t("Time (WIB)")}
              // required
              options={options}
              containerStyle={{ width: 204 }}
              useFormObj={useFormObj}
              name="time"
              // defaultValue={dummyOption[0]}
              placeholder={t("Select time")}
            />
          </div>
          <CustomResultBanner showResult={showResult} text={text} />
        </div>
        <div
          style={{
            padding: 20,
            borderTop: "1px solid #E6E6E8",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div style={{ display: "flex", gap: 16 }}>
            <ButtonJack
              type="outline"
              style={{ width: 66 }}
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonJack>
            <ButtonJack
              style={{ width: 52 }}
              onClick={handleSubmit(onSubmit)}
              disabled={!showResult}
            >
              {t("Save")}
            </ButtonJack>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const DaysInput = ({ useFormObj, name, defaultValue = [] }) => {
  const { t } = useTranslation("schedule-payment/payment-cycle");
  const { register, setValue, watch } = useFormObj;
  const currValue = watch(name) ?? [];

  const handleClick = (value) => {
    const isFalsy = isEmpty(currValue);
    const isExist = currValue?.includes(value);
    const isSingle = currValue?.length == 1;

    if (isSingle && isExist) return;
    if (isFalsy) return setValue(name, [value]);
    if (isExist)
      return setValue(
        name,
        currValue.filter((val) => val != value)
      );

    const newValue = [...currValue, value];
    return setValue(name, newValue);
  };

  useEffect(() => {
    register(name);
    //setValue(name, defaultValue);
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <GothamRegular className="font12">
        {t("Select Day(s)")}
        <TextInlineRegular style={{ color: "red" }}>*</TextInlineRegular>
      </GothamRegular>
      <div style={{ display: "flex", gap: 12 }}>
        {days?.map(({ label, value }) => {
          const isActive = currValue?.includes(value);
          return (
            <div
              className="preventblueblock"
              style={{
                width: 42,
                height: 42,
                borderRadius: 4,
                border: `${isActive ? 2 : 1}px solid ${
                  isActive ? "#343434" : "#E6E6E8"
                }`,
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleClick(value)}
            >
              <GothamMedium>{label}</GothamMedium>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const SetDefaultCheckbox = ({
  useFormObj,
  name,
  defaultValue = false,
  value,
}) => {
  const { register, watch, setValue } = useFormObj;
  const currValue = watch(name) ?? false;
  const handleClick = () => {
    setValue(name, !currValue);
  };

  const titleDecider = (ID) => {
    if (ID) return `Jadikan Jadwal utama untuk ${titleCase(label)}`;
    return `Set as ${titleCase(label)} default schedule`;
  };

  const descDecider = (ID) => {
    if (ID)
      return `Transaksi ${titleCase(
        label
      )} akan otomatis mengikuti jadwal pembayaran ini. Anda bisa mengubahnya kapan saja.`;

    return `${label} transactions will follow this schedule automatically. You can
    change it anytime.`;
  };
  const label = productsMenuOptions
    ?.filter(({ value: optionVal }) => optionVal == value)
    ?.pop()?.label;

  useEffect(() => {
    register(name);
    //setValue(name, defaultValue);
  }, []);
  return (
    <div
      style={{
        height: 44,
        display: "flex",
        gap: 8,
        alignItems: "start",
      }}
      className="smoothIn"
    >
      <CheckBox isActive={currValue} onClick={handleClick} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <GothamRegular>{titleDecider(isID)}</GothamRegular>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          {descDecider(isID)}
        </GothamRegular>
      </div>
    </div>
  );
};

const RadioOption = ({ label, isActive, handleClick, isDisabled }) => {
  return (
    <div style={{ display: "flex", gap: 8, paddingLeft: 28 }}>
      <CheckBox isActive={isActive} type="circle" onClick={handleClick} />
      <GothamRegular style={{ color: isDisabled ? "#BBBBC0" : "#343434" }}>
        {label}
      </GothamRegular>
    </div>
  );
};

export const Counter = ({
  useFormObj,
  name,
  defaultValue = 0,
  max = 30,
  min = 0,
}) => {
  const { watch, setValue, register } = useFormObj;

  const currValue = watch(name);
  const isString = typeof currValue == "string";
  const passLimit = currValue > max;
  const formattedCurrValue = `${currValue}`?.replace(/[^0-9]/g, "");
  const handleCountUp = () => {
    if (Number(currValue) + 1 > max) return;
    return setValue(name, +(Number(currValue) + 1));
  };
  const handleCountDown = () => {
    if (Number(currValue) - 1 < 0) return;
    return setValue(name, +(Number(currValue) - 1));
  };

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    return setValue(name, formattedCurrValue);
  }, [currValue]);

  useEffect(() => {
    if (passLimit) return setValue(name, +Number(max));
  }, [passLimit]);

  return (
    <div
      style={{
        position: "relative",
      }}
      className="preventblueblock"
    >
      <TextFieldJack
        useFormObj={useFormObj}
        woLabel
        name={name}
        style={{
          height: 40,
          width: 84,
          margin: 0,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          paddingRight: 10,
        }}
      >
        <JackIcons
          name="chevron_up"
          fill="#BBBBC0"
          className="iconHover"
          style={{ width: 20, height: 20, cursor: "pointer" }}
          onClick={handleCountUp}
        />
        <JackIcons
          name="chevron_down"
          className="iconHover"
          fill="#BBBBC0"
          style={{ width: 20, height: 20, cursor: "pointer" }}
          onClick={handleCountDown}
        />
      </div>
    </div>
  );
};

const BusinessDayOptions = ({ useFormObj, name, defaultValue = "" }) => {
  const { register, setValue, watch } = useFormObj;
  const currValue = watch(name);
  const isFriday = currValue === "Friday";
  const isMonday = currValue === "Monday";
  const [isActive, setIsActive] = useState(false);
  const handleClick = () =>
    setIsActive((p) => {
      if (p) setValue(name, "");
      return !p;
    });

  const handleClickRadio = (val) => {
    if (!isActive) return;
    return setValue(name, val);
  };

  useEffect(() => {
    register(name);
    //setValue(name, "");
  }, []);

  useEffect(() => {
    if (isFriday || isMonday) return setIsActive(true);
  }, [currValue]);

  const { t } = useTranslation("schedule-payment/payment-cycle");
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 16 }}
      className="preventblueblock smoothIn"
    >
      <div
        style={{
          height: 44,
          display: "flex",
          gap: 8,
          alignItems: "start",
        }}
      >
        <CheckBox isActive={isActive} onClick={handleClick} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            color: isActive ? "#909098" : "#BBBBC0",
          }}
        >
          <GothamRegular>{t("Only pay on business days")}</GothamRegular>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {t("Adjust payments that occur on weekends")}
          </GothamRegular>
        </div>
      </div>
      <>
        <RadioOption
          isDisabled={!isActive}
          label={t("Payment postponed to the next Monday")}
          isActive={isMonday}
          handleClick={() => handleClickRadio("Monday")}
        />
        <RadioOption
          isDisabled={!isActive}
          label={t("Payment advanced to Friday")}
          isActive={isFriday}
          handleClick={() => handleClickRadio("Friday")}
        />
      </>
    </div>
  );
};

export const AddPaymentCycleForm = ({
  useFormObj,
  containerStyle,
  product,
  isRightModal = false,
  defaultValue,
  isEdit = false,
}) => {
  const { t } = useTranslation("schedule-payment/payment-cycle");
  const { watch, reset, register, setValue, getValues } = useFormObj;
  const isInvoice = product == "InvoiceTransaction";
  const rangeVal = watch("recurrence");
  const isDayRange = rangeVal?.value == "day";
  const isWeekRange = rangeVal?.value == "week";
  const isMonthRange = rangeVal?.value == "month";
  const isYearRange = rangeVal?.value == "year";

  useEffect(() => {
    resetForm();
  }, []);

  const showResultDecider = () => {
    const timeVal = watch("time");
    const repeatEvery = watch("repeat_every");
    const daysVal = watch("days");
    const dateVal = watch("date");

    if (isDayRange) return timeVal && repeatEvery;
    if (isWeekRange) return timeVal && repeatEvery && daysVal;
    return timeVal && repeatEvery && dateVal;
  };

  const isToggle = showResultDecider();
  const resetForm = (woRecurencce = false) => {
    setValue(
      "transaction_type",
      productsMenuOptions.filter((v) => v.value == product).pop()
    );
    setValue("repeat_every", 1);
    !woRecurencce && setValue("recurrence", timeRange[0]);
    setValue("days", []);
    setValue("date", null);
    setValue("time", null);
  };

  const textDecider = (obj) => {
    const payload = payloadMaker(getValues());
    const { non_business_days_skip_to: skipTo } = payload;
    const isMonday = skipTo == "Monday";
    let text = "";
    let helperText = "";

    if (isDayRange) text = formatScheduleString(payload)?.text;
    if (isWeekRange) text = getNextPaymentDates(payload)?.text;
    if (isMonthRange) text = getMonthlySummary(payload);
    if (isYearRange) text = getYearlySummary(payload);

    if (skipTo) helperText = "";

    return { text, helperText };
  };

  useEffect(() => {
    if (Boolean(defaultValue?.transaction_type))
      return setFormDefaultValue({ defaultValue, useFormObj });
  }, [defaultValue]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        ...containerStyle,
      }}
    >
      {!isRightModal && !isInvoice && (
        <SelectionJack
          useFormObj={useFormObj}
          options={productsMenuOptions}
          containerStyle={{ margin: 0 }}
          divStyle={{ height: 64 }}
          name="transaction_type"
          label={t("Transaction Type")}
          required
          isDisabled={isEdit}
          helperText={isEdit && "Transaction cannot be changed"}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 24,
          paddingLeft: isRightModal && isInvoice && 28,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 16,
            alignItems: "center",
            height: 40,
          }}
        >
          <GothamRegular>{t("Repeat every")}</GothamRegular>
          <Counter useFormObj={useFormObj} name="repeat_every" />
          <SelectionJack
            noLabel
            required
            options={timeRange}
            useFormObj={useFormObj}
            onChange={() => resetForm(true)}
            name="recurrence"
            containerStyle={{
              marginBottom: 0,
              height: 40,
              position: "relative",
              top: 0,
            }}
            customLabelStyle={{ display: "none" }}
            divStyle={{ margin: 0, height: 40 }}
          />
        </div>
        {isWeekRange && <DaysInput name="days" useFormObj={useFormObj} />}
        {isDayRange || isWeekRange ? (
          <SelectionJack
            label={t("Time (WIB)")}
            required
            options={dummyOption}
            useFormObj={useFormObj}
            name="time"
            // defaultValue={dummyOption[0]}
            placeholder={t("Select time")}
            containerStyle={{ margin: 0 }}
          />
        ) : (
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div style={{ width: "calc(50% - 16px)" }}>
              <DateInputJackUseForm
                label={redStarLabel(t("Start Date"))}
                style={{ margin: 0 }}
                useFormObj={useFormObj}
                containerStyle={{ margin: 0 }}
                name="date"
                minDate={new Date()}
                placeholder={t("Select date")}
                dateFormat="d/MM/yyyy"
              />
            </div>
            <div style={{ width: "calc(50% - 16px)" }}>
              <SelectionJack
                label={redStarLabel(t("Time (WIB)"))}
                required
                options={dummyOption}
                containerStyle={{ margin: 0 }}
                useFormObj={useFormObj}
                name="time"
                // defaultValue={dummyOption[0]}
                placeholder={t("Select time")}
                woAsterisk
              />
            </div>
          </div>
        )}
      </div>
      {isToggle && (
        <>
          <SetDefaultCheckbox
            value={product}
            name="is_default"
            useFormObj={useFormObj}
          />
          {(isMonthRange || isYearRange) && (
            <BusinessDayOptions
              name="non_business_days_skip_to"
              useFormObj={useFormObj}
            />
          )}
          <CustomResultBanner
            showResult={isToggle}
            text={textDecider().text}
            helperText={textDecider().helperText}
          />
        </>
      )}
    </div>
  );
};

export const AddPaymentCycleModal = ({
  isOpen,
  toggle,
  product,
  refetch,
  setPayment,
}) => {
  const { t } = useTranslation("schedule-payment/payment-cycle");
  const useFormObj = useForm();
  const { setValue, register, handleSubmit } = useFormObj;
  const { successSnackBar } = ToasterHook();
  // const onSubmit = (val) => console.log(val);
  const toasterMSG = t("You’ve created a payment cycle!");
  const { mutation: createPaymentCycle, loading } = useMutation({
    url: "/predefined_schedules",
    method: "post",
    afterSuccess: ({ data: responseData }) => {
      successSnackBar({
        msg: toasterMSG,
        showClose: true,
      });
      setPayment(createPCPayload(responseData?.data));
      toggle();
    },
  });
  const onSubmit = async (val) => {
    const payload = payloadMaker(val);
    await createPaymentCycle(payload);
    return refetch();
  };
  useEffect(() => {
    if (product) {
      register("transaction_type");
      setValue(
        "transaction_type",
        productsMenuOptions.filter((v) => v.value == product).pop()
      );
    }
  }, [product]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="my-0"
      centered
      style={{ width: 480 }}
    >
      <ModalHeader
        title={t("Create Payment Cycle")}
        subtitle={t(
          "Create a payment schedule template that works best for your company"
        )}
        handleClose={toggle}
      />
      {/* <AddPaymentCycleForm
        useFormObj={useFormObj}
        containerStyle={{ padding: "20px 20px 32px" }}
      /> */}
      <div style={{ padding: "20px 20px 32px" }}>
        <FormDecider useFormObj={useFormObj} isEdit />
      </div>
      <ModalButtons
        onClickCancel={toggle}
        onClickSave={handleSubmit(onSubmit)}
      />
    </Modal>
  );
};

export const PaymentCycleOption = ({
  isActive,
  description,
  onClick,
  product,
  list,
  refetch,
  loading,
  isDisabled,
}) => {
  const { t } = useTranslation("schedule-payment/release");
  const {
    isOpen: isOpenPCL,
    toggle: togglePaymentCycleList,
    close: closePCL,
  } = useModalHook();
  const {
    isOpen: isOpenAddPC,
    open: openAddPc,
    toggle: toggleAddPc,
  } = useModalHook();

  const { isAdmin, isSuperAdmin } = getUserRole();
  const canCreate = isAdmin || isSuperAdmin;
  const showDescription = !isOpenPCL && Boolean(description);
  const text = showDescription ? description : t("Select payment cycle");
  const Gotham = showDescription ? GothamMedium : GothamRegular;
  return (
    <ModalOption
      isDisabled={isDisabled}
      type="payment_cycle"
      isActive={isActive}
      onClick={onClick}
    >
      <div
        style={{
          height: 40,
          width: "100%",
          borderRadius: 8,
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
          border: `1.5px solid ${showDescription ? "#E6e6e8" : "#343434"}`,
          backgroundColor: showDescription ? "#fff" : "#FFF6E6",
          position: "relative",
        }}
      >
        <Gotham
          className="font12 mb-0 ellipsis"
          style={{
            color: showDescription ? "#6087e7" : "#343434",
          }}
        >
          {text}
        </Gotham>
        <JackIcons
          name={isOpenPCL ? "chevron_up" : "chevron_down"}
          fill="#6c6c71"
          onClick={(e) => {
            e?.stopPropagation();
            togglePaymentCycleList();
          }}
        />
        {isOpenPCL && (
          <PaymentCycleList
            product={product}
            canCreate={canCreate}
            list={list}
            loading={loading}
            refetch={refetch}
            setPayment={(payment) => {
              onClick(_, payment);
              closePCL();
            }}
            isOpenAdd={isOpenAddPC}
            toggleAdd={toggleAddPc}
            containerStyle={{ top: 45 }}
            woBack
          />
        )}
      </div>
    </ModalOption>
  );
};

export const SpesificDateOption = ({ onChange, defaultValue }) => {
  const { t } = useTranslation("schedule-payment/release");
  const useFormObj = useForm();
  const { watch, setValue } = useFormObj;
  const dateValue = watch("date");
  const timeValue = watch("time");

  const canAssignValue = Boolean(dateValue);

  const assignValue = (timeVal) => {
    const date_and_time = changeHour(dateValue, timeVal);

    return onChange("value", {
      type: "spesific_date",
      payload: { is_scheduled: true, date_and_time },
    });
  };

  // useEffect(() => {
  //   if (dateValue && timeValue) return setValue("time", null);
  // }, [dateValue]);

  // useEffect(() => {
  //   if (canAssignValue) return assignValue();
  // }, [assignValue]);

  const options = generateTimeOptionsComplex(
    dateValue || defaultValue?.date_and_time,
    false
  );

  const selectionDefaultValue = () => {
    if (defaultValue?.date_and_time) {
      const hour = defaultValue?.date_and_time
        ?.getHours()
        ?.toString()
        .padStart(2, "0");
      const completeHour = `${hour}:00`;
      const label = completeHour + " WIB";
      return { label, value: completeHour };
    }

    return {};
  };

  return (
    <div style={{ display: "flex", gap: 10, width: "100%" }}>
      <div style={{ width: "50%" }}>
        <DateInputJackUseForm
          label={redStarLabel("Date")}
          style={{ width: "100%" }}
          minDate={new Date()}
          useFormObj={useFormObj}
          name="date"
          placeholder={t("Select date")}
          dateFormat="d MMM yyyy"
          noLabel
          defaultValue={defaultValue?.date_and_time}
          type="BLUE-YELLOW"
          startAdornment={
            <JackIcons
              name="calendar_outline"
              fill={dateValue ? jackColors.blue60 : jackColors.black34}
              style={{ height: 20, width: 20, marginRight: 4 }}
            />
          }
          onChange={(dateValue) => {
            setValue("date", dateValue);
            //if date change time value will reset
            onChange("value", {
              type: "spesific_date",
            });
            setValue("time", null);
          }}
        />
      </div>
      <div style={{ width: "50%" }}>
        <SelectionJack
          label={"Time (WIB)"}
          options={options}
          containerStyle={{ width: "100%" }}
          useFormObj={useFormObj}
          name="time"
          defaultValue={selectionDefaultValue()}
          placeholder={t("Select time")}
          noLabel
          type="custom"
          style={{ color: "#608737" }}
          components={{ Control: BlueYellowSelection }}
          customStyleFunc={blueYellowStyle}
          onChange={(value) => {
            setValue("time", value);
            if (canAssignValue) assignValue(value.value);
          }}
        />
      </div>
    </div>
  );
};
const OptionContent = ({
  onClickBack,
  onSaveChanges,
  currentPayment,
  list,
  refecthList,
  loadingFetchList,
  product,
  isChooseDanamonBalance,
}) => {
  const { t } = useTranslation("schedule-payment/release");
  const [modalValue, setModalValue] = useState(() => currentPayment);
  const { type, description, payload } = modalValue;
  const paymentType = type;
  const isSpesificDate = paymentType == "spesific_date";
  const isPaymentCycle = paymentType == "payment_cycle";
  const isPayNow = paymentType == "default";

  const defaultValueDecider = () => {
    const parentType = currentPayment?.type;
    const isSpecificDateParent = parentType == "spesific_date";
    const parentDateandTime = currentPayment?.payload?.date_and_time;
    if (isSpecificDateParent && parentDateandTime)
      return currentPayment?.payload;

    return null;
  };

  const spesificDateDefaultValue = defaultValueDecider();

  const handleChangeOption = (property, value) => {
    if (!property) return;
    if (property == "type") return setModalValue({ type: value });

    return setModalValue(value);
  };

  const disableContinue = isEmpty(payload) && !isPayNow;

  const { successSnackBar } = ToasterHook();

  const dynamicOption = (
    <>
      <ModalOption
        type="spesific_date"
        isActive={isSpesificDate}
        onClick={handleChangeOption}
        isDisabled={isChooseDanamonBalance}
      >
        <SpesificDateOption
          onChange={handleChangeOption}
          defaultValue={spesificDateDefaultValue}
        />
      </ModalOption>
      <PaymentCycleOption
        isActive={isPaymentCycle}
        description={description}
        onClick={handleChangeOption}
        list={list}
        refetch={refecthList}
        loading={loadingFetchList}
        product={product}
        isDisabled={isChooseDanamonBalance}
      />
    </>
  );

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <div
        style={{
          gap: 32,
          position: "relative",
          padding: 16,
          height: "calc(100% - 80px)",
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <GothamMedium className="font24">
          {t("Change Payment Schedule")}
        </GothamMedium>
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <ModalOption
            type="default"
            isActive={isPayNow}
            onClick={handleChangeOption}
          />
          {isChooseDanamonBalance && (
            <div
              style={{
                position: "relative",
                border: `1px solid ${jackColors.neutral500}`,
                padding: 8,
                borderRadius: 8,
              }}
            >
              <div
                className="d-flex justify-content-start align-items-center"
                style={{
                  border: `1px solid ${jackColors.neutral500}`,
                  borderRadius: 8,
                  backgroundColor: jackColors.neutral400,
                  padding: "4px 8px",
                  marginBottom: 16,
                }}
              >
                <JackIcons
                  style={{ height: 12, width: 12, marginRight: 4 }}
                  name="info"
                  fill={jackColors.neutral700}
                />
                <GothamRegular
                  className="font10"
                  style={{
                    color: colors.grey6c,
                    lineHeight: "12px",
                  }}
                >
                  <span
                    className="font10"
                    style={{
                      fontFamily: "GothamBold",
                      color: colors.grey6c,
                      lineHeight: "12px",
                    }}
                  >
                    {t(
                      "Since you pay with Giro Danamon, scheduled payments aren't available."
                    )}
                  </span>
                </GothamRegular>
              </div>
              {dynamicOption}
            </div>
          )}
          {!isChooseDanamonBalance && dynamicOption}
        </div>
      </div>
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        {(isSpesificDate || isPaymentCycle) && (
          <ScheduleInfo dateObj={payload} />
        )}
        <div
          style={{
            display: "flex",
            height: 80,
            width: "100%",
            gap: 16,
            padding: 20,
            border: "1px solid #e6e6e8",
          }}
        >
          <ButtonJack
            type="outline"
            style={{ width: "50%" }}
            onClick={onClickBack}
            leftIcon={<JackIcons name="arrow_back" fill={"#343434"} />}
          >
            {t("Back")}
          </ButtonJack>
          <ButtonJack
            style={{ width: "50%" }}
            onClick={() => {
              onSaveChanges(modalValue);
              if (isSpesificDate || isPaymentCycle) {
                const dateAndTime = payload?.date_and_time;

                successSnackBar({
                  msg: toasterText(dateAndTime, t),
                  showClose: true,
                });
              }
            }}
            disabled={disableContinue}
            leftIcon={
              <JackIcons
                name="save_outline"
                fill={disableContinue ? "#909098" : "#343434"}
              />
            }
          >
            {t("Save Changes")}
          </ButtonJack>
        </div>
      </div>
    </div>
  );
};

const toasterText = (dateRaw, translate) => {
  const date = handleDate(dateRaw);
  const textDate = format(date, "eeee, d MMMM yyyy (HH:mm 'WIB')", {
    locale: isID ? id : enUS,
  });

  return (
    <GothamRegular>
      {translate("Payment schedule is set for")}{" "}
      <TextInlineMedium>{textDate}</TextInlineMedium>
    </GothamRegular>
  );
};

export const ScheduleInfo = ({ dateObj }) => {
  const { t } = useTranslation("schedule-payment/release");
  const { locale } = useRouter();
  const isID = locale == "id";
  const isEmptyDate = isEmpty(dateObj);
  const dateRaw = dateObj?.date_and_time;
  const stringDate = typeof dateRaw == "string";

  const date = stringDate ? new Date(dateRaw) : dateRaw;

  const textFormat = isID
    ? "EEEE',' dd MMMM yyyy '('HH:mm 'WIB).'"
    : "EEEE dd MMMM yyyy 'at' HH:mm 'WIB.'";
  const dateText = date
    ? format(date, textFormat, {
        locale: isID ? id : enUS,
      })
    : "";
  const defaultText = t("New payment schedule will appear here.");
  return (
    <div
      style={{
        width: "100%",
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        backgroundColor: isEmptyDate ? "#f1f1f1" : "#6087e7",
        border: isEmptyDate && "#e6e6e8",
        display: "flex",
        gap: 8,
        padding: "8px 12px",
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      <JackIcons
        name="calendar"
        fill={isEmptyDate ? "#909098" : "#fff"}
        style={{ width: 20, height: 20 }}
      />
      {isEmptyDate ? (
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          {defaultText}
        </GothamRegular>
      ) : (
        <GothamRegular className="font12 text-white">
          {t("Scheduled for")} <TextInlineMedium>{dateText}</TextInlineMedium>
        </GothamRegular>
      )}
    </div>
  );
};

export const PaymentOptionRightModal = ({
  isOpen,
  toggle,
  currentPayment,
  handleChangeOption,
  predefinedSchedules,
  refetchList,
  loadingFetchList,
  product,
  isChooseDanamonBalance = false,
}) => {
  const { toListener, toToggle } = useSmoothRightModal();
  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      listener={toListener}
      array={[
        <OptionContent
          onClickBack={toToggle}
          currentPayment={currentPayment}
          onSaveChanges={(v) => {
            handleChangeOption(_, v);
            toggle();
          }}
          list={predefinedSchedules}
          refecthList={refetchList}
          loadingFetchList={loadingFetchList}
          product={product}
          isChooseDanamonBalance={isChooseDanamonBalance}
        />,
      ]}
      noPadding
    />
  );
};

export const BlueYellowSelection = ({ children, ...props }) => {
  const { hasValue } = props;
  return (
    <components.Control {...props}>
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "0px 10px 0px ",
          alignItems: "center",
        }}
      >
        <JackIcons
          name="clock_outline"
          fill={hasValue ? "#6087e7" : "#343434"}
          style={{ width: 20, height: 20 }}
        />{" "}
        {children}
      </div>
    </components.Control>
  );
};
