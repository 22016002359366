export const useEddHook = (transaction) => {
  const {
    need_edd,
    edd_status: edd_document_status,
    edd_submited: eddSubmittedDate,
  } = transaction || {};

  const needEdd = need_edd && edd_document_status === "created";
  const hasSubmittedEdd = need_edd && edd_document_status !== "created";
  // const hasSubmittedEdd = true;
  // const needEdd = false;

  const isEddTrx = hasSubmittedEdd || needEdd;

  return { hasSubmittedEdd, isEddTrx, needEdd, eddSubmittedDate };
};
