import { format } from "date-fns";
import { booleanState } from "../../../../components/Status";
import { deepRemoveDuplicates } from "../../../../components/tools";
import { categoryChecker } from "../../../categoryComponents/general/helpers";

export const transactionsFormatter = (res, prev, setIsMaxedOut) => {
  const array = res?.data?.data || [];
  if (!array.length) setIsMaxedOut(true);

  const resultArr = deepRemoveDuplicates(
    [...(prev?.array || []), ...array],
    "id"
  );

  const result = resultArr.map((item) => {
    const { created_at, category, has_attachment, details, is_external } = item;
    const date = format(new Date(created_at), "dd/MM/yy");

    const { isCardGeneral } = booleanState(category);

    const defaultItems = { ...item, date };

    if (isCardGeneral)
      return {
        ...defaultItems,
        has_attachment:
          has_attachment || (details?.files?.file_details || []).length,
        has_notes: !!details?.notes,
        is_external,
      };

    return defaultItems;
  });

  const hasBalanceHistory = !!resultArr.filter(({ category }) => {
    const { isWalletHistory, isCardGeneral } = booleanState(category);

    return isWalletHistory || isCardGeneral;
  }).length;

  const hasMissingCategory = !!resultArr.find(({ details }) => {
    const { hasCategory } = categoryChecker({ details });

    return !hasCategory;
  });

  const hasNeedEdd = !!resultArr.find(({ details }) => {
    if (
      details?.need_edd === true &&
      (details?.edd_document_status === "created" ||
        details?.edd_status === "created")
    )
      return true;
  });

  return { array: result, hasBalanceHistory, hasMissingCategory, hasNeedEdd };
};
