import { noCase, sentenceCase, titleCase } from "change-case";
import { upperCase } from "lodash";
import { jackColors } from "../../../../../assets/colors";
import { CurrencyFieldBoolean, FieldBoolean } from "./boolean";
import { ErrorMaxLengthField } from "./logic";
import { useLanguage } from "public/locales/translationFunctions";

export const ErrorTextGenerator = ({ field, currency, useFormObj }) => {
  const { isBahasa } = useLanguage();
  const { errors } = useFormObj;
  const isErrorProps = !!errors[field];

  if (isErrorProps)
    return isBahasa ? "Mohon isi kolom ini" : `Please fill out this field`;
  return "";
};

export const PlaceHolderDecider = (field, label, t = () => {}) => {
  if (field === "recipient_email") return `${t("Enter")} email`;
  return `${t("Enter")} ${noCase(label)}`;
};

export const OptionalTextGenerator = ({ field }) => {
  const { isBahasa } = useLanguage();
  return (
    <>
      {field}{" "}
      <span className="font10" style={{ color: jackColors.neutral700 }}>
        ({isBahasa ? "opsional" : "optional"})
      </span>
    </>
  );
};

export const LabelDecider = (name, currency, t = () => {}) => {
  const {
    isBankId,
    isSwiftBIC,
    isRecipientEmail,
    isIdType,
    isIdNumber,
    isFirstName,
    isLastName,
    isCountryIsoCode,
    isMSISDN,
    isNationalityIsoCode,
    isCompanyRegistrationNumber,
    isCompanySocialCreditCode,
    isIfsCode,
  } = FieldBoolean(name);

  if (isBankId) return t("Bank Name");
  if (isSwiftBIC) return t("SWIFT/BIC Code");
  if (isRecipientEmail) return <OptionalTextGenerator field={t("Email")} />;
  if (isIdType) return t("ID Type");
  if (isIdNumber) return t("ID Number");
  if (isFirstName) return t("First Name");
  if (isLastName) return t("Last Name");
  if (isCountryIsoCode) return t("Country");
  if (isMSISDN) return t("Phone Number");
  if (isNationalityIsoCode) return t("Nationality");
  if (isIfsCode) return t("IFS Code");
  if (isCompanySocialCreditCode) return "Company Social Credit Code (USCC)";
  if (isCompanyRegistrationNumber)
    return <OptionalTextGenerator field={t("Company Registration Number")} />;

  // --------------------------------------------------------------- //

  if (name == "receiving_registration_number_optional")
    return <OptionalTextGenerator field={t("Registration Number")} />;
  if (name === "receiving_address_text_area") return t("Address");
  if (name == "recipient_email_required") return t("Recipient Email");
  if (name == "routing_code_cny" || name == "cnaps") return t("CNAPS Code");
  if (name == "bank_account_name") return t("Account Name");
  if (name == "bank_name_field") return t("Bank Name");
  if (name.includes("receiving_")) {
    const newLabel = name.replace("receiving_", "");
    if (newLabel === "registered_name" && currency === "CNY")
      return t("Business Name");
    return t(titleCase(newLabel));
  }
  if (name == "beneficiary_country_code") return t("Country");
  if (name.includes("beneficiary_")) {
    const newLabel = name.replace("beneficiary_", "");
    return t(titleCase(newLabel));
  }

  if (name.includes("recipient_"))
    return t(titleCase(name.replace("recipient_", "")));

  if (name == "MSISDN") return t("Phone Number");

  if (name == "iban") return t(upperCase(name));

  if (name == "sort_code") return t("Sort Code");

  if (name == "bsb_number") return t("BSB Number");

  if (name == "aba_routing_number") return t("ABA Routing Number");

  return t(titleCase(name));
};

export const HelperTextGenerator = (field, currency, t = () => {}) => {
  const { isCNY } = CurrencyFieldBoolean(currency);
  const {
    isRecipientEmail,
    isAbaRoutingNumber,
    isRegisteredName,
    isPhoneNumber,
    isAddressTextArea,
    isAccountNumber,
  } = FieldBoolean(field);

  if (isCNY) {
    if (isRegisteredName)
      return t(
        "Must be filled in English. Make sure the business name matches the bank account name."
      );
    if (isRecipientEmail)
      return t(
        "The recipient will receive an email when the money is on its way."
      );
    if (isPhoneNumber)
      return t(
        "Ensure it's correct. The recipient's bank will contact the recipient to confirm this transaction."
      );
    if (isAddressTextArea) return t("Must be filled in English.");
    if (isAccountNumber)
      return t(
        "Must be filled with the business' bank account number, not a card number."
      );
  }
  if (isAbaRoutingNumber)
    return t("Please use routing number for ACH transaction");
  if (isRecipientEmail)
    return t(
      "The recipient will receive an email when the money is on its way."
    );
  return;
};
