import { useEffect, useRef, useState } from "react";
import { jackColors } from "../../assets/colors";
import { ReasonNotes } from "../../pageComponents/payrollCreateComponents/loop/success/status";
import { useModalHook } from "../Modals";
import { GothamMedium, GothamRegular } from "../Text";
import Line from "./line";
import { updatesFormatter } from "./logics";
import Text from "./text";
import { isEmpty } from "lodash";
import { JackIcons } from "../../assets/jackIcons/parent";
import { useTranslation } from "react-i18next";

const updatesTemplate = [
  {
    text: "",
    isActive: false,
    date: "",
    data: [],
  },
];

const UpdateTracker = ({
  updates: updatesRaw = updatesTemplate,
  rejectionNote = null,
  isEditHistory = false,
  disableToggle = false,
}) => {
  const { isOpen: isOpenSeeAll, toggle } = useModalHook();
  const filteredUpdatesRaw = updatesRaw.filter((update) => !isEmpty(update));
  const { updates, isMoreThanFour, activeIndex } = updatesFormatter({
    updates: filteredUpdatesRaw,
    isOpenSeeAll,
  });
  const [height, setHeight] = useState(0);
  const { t } = useTranslation("payroll/create");
  const { t: tCreditCards } = useTranslation("credit/credit");
  const { t: tInternational } = useTranslation("international/create");

  const textDefaultStyle = {
    fontSize: "12px",
    lineHeight: "16px",
    color: jackColors.neutral700,
  };

  useEffect(() => {
    if (disableToggle) toggle();
  }, []);

  return (
    <div
      style={{
        height,
        transition: "all 0.4s linear",
        borderRadius: "4px",
        backgroundColor: jackColors.neutral400,
        overflowY: "hidden",
      }}
    >
      <div
        ref={(e) => {
          if (!e) return;
          setHeight(e.clientHeight);
        }}
        style={{
          paddingTop: "12px",
          paddingBottom: "12px",
        }}
      >
        {updates?.map((update, index) => {
          const isFirst = index === 0;
          const isLast = index === updates.length - 1;

          const {
            text,
            isActive,
            date,
            data,
            isSteps,
            isNoLine,
            isSuccess,
            isCustomText: isCustomTextRaw,
            isCompleted,
            isPartialFailed,
            isFailed,
            isRetry,
            reason,
            isUserLog,
            isAfterSubmitDoc,
            forceCurrentUpdate,
          } = update;

          const isCustomText = isCustomTextRaw || (data ?? [])[0]?.isCustomText;

          const isCurrentUpdate = activeIndex === index;

          const isRejected =
            !!(data ?? [])[0]?.rejected_at || update.isRejected;

          const joinedText = isCustomText
            ? (data ?? [])[0]?.text
            : (data ?? []).map(({ text }) => text).join("") || "";

          const isWorkflowUpdate = !!joinedText;

          const lineProps = {
            isLast,
            isFirst,
            isSteps,
            isActive,
            isNoLine,
            isRejected,
            isCurrentUpdate,
            isCompleted,
            isSuccess,
            isPartialFailed,
            isFailed,
            isUserLog,
            isRetry,
            isAfterSubmitDoc,
            forceCurrentUpdate,
          };

          const translateTextDecider = () => {
            if (isActive || isSteps) return "0px 0px";
            return "0px 3px";
          };

          const translateText = translateTextDecider();

          const containerDefaultStyle = {
            paddingBottom: !isLast && 16,
            position: "relative",
            display: "flex",
          };
          const textContainerDefaultStyle = {
            width: "100%",
            translate: translateText,
          };

          const thereIsNothingToRender = !text && !data;

          if (thereIsNothingToRender) return null;

          const getItem = (text, date, isCustomText, index) => (
            <div style={containerDefaultStyle} key={index}>
              <Line {...lineProps} />
              <div style={textContainerDefaultStyle}>
                <Text item={update} isCustomText={isCustomText}>
                  {text}
                </Text>

                {rejectionNote && isLast && (
                  <ReasonNotes
                    text={rejectionNote}
                    style={{}}
                    label={tCreditCards("Reason")}
                  />
                )}

                {reason && (
                  <ReasonNotes
                    label={
                      !isEditHistory
                        ? tInternational("Reason")
                        : "Notes on editing"
                    }
                    text={reason}
                    style={{}}
                  />
                )}

                <div style={{ whiteSpace: "nowrap" }}>
                  <GothamRegular style={textDefaultStyle}>{date}</GothamRegular>
                </div>
              </div>
            </div>
          );

          if (isWorkflowUpdate)
            return getItem(joinedText, data[0].date, isCustomText, index);

          return getItem(text, date, isCustomText, index);
        })}

        {!disableToggle && isMoreThanFour && (
          <div
            className="d-flex justify-content-between"
            style={{
              marginTop: 16,
              paddingLeft: 24,
              paddingRight: 24,
              marginLeft: 12,
              marginRight: 12,
              paddingTop: isOpenSeeAll ? 8 : 0,
              paddingBottom: 0,
              borderTop: isOpenSeeAll ? `1px solid ${jackColors.greyE6}` : "",
            }}
            onClick={toggle}
          >
            <GothamMedium
              style={{
                color: jackColors.neutral900,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="font12"
            >
              {isOpenSeeAll ? t("See less") : t("See all updates")}
            </GothamMedium>
            <JackIcons
              name="chevron-up-outline"
              fill={jackColors.black34}
              style={{
                width: 20,
                height: 20,
                opacity: isOpenSeeAll ? 1 : 0,
                transition: "opacity 0.4s linear",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateTracker;
