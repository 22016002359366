import { useTranslation } from "react-i18next";

export const updatesFormatter = ({
  updates: updatesRaw = [],
  isOpenSeeAll,
}) => {
  let updates = updatesRaw;

  const isMoreThanFour = updates.length > 4;
  const isNormal = !(isMoreThanFour && !isOpenSeeAll);
  const { t } = useTranslation("payroll/create");

  const activeIndex = updates
    .map(({ isActive }, index) => {
      if (isActive) return index;
      return null;
    })
    .filter((index) => index)
    .pop();

  if (isNormal) return { updates, isMoreThanFour, activeIndex };

  const sliceLength = (first, last) => updates.slice(first, last).length;

  const stepsBefore = sliceLength(1, activeIndex);
  const stepsAfter = sliceLength(activeIndex, updates.length - 1);

  const tempUpdates = [];
  const pushTemp = (obj) => tempUpdates.push(obj);

  pushTemp({ ...updates[0], isNoLine: !!stepsBefore });

  const defaultObj = { isSteps: true, isNoLine: true };
  const isShowAfterActive = updates[activeIndex]?.isShowAfterActive || false;
  const isShowBeforeActive =
    updates[activeIndex - 1]?.isShowBeforeActive || false;
  const isShowAfterBeforeActive = isShowBeforeActive || isShowAfterActive; // show after/before Active with more than 4 updates and condition see less (international edd)

  if (stepsBefore) {
    pushTemp({
      text: `${stepsBefore - (isShowBeforeActive ? 1 : 0)} ${t(
        "completed steps"
      )}`,
      ...defaultObj,
    });
  }

  if (isShowBeforeActive) {
    pushTemp({
      ...updates[activeIndex - 1],
    });
  }

  pushTemp({
    ...updates[activeIndex],
    isNoLine: isShowAfterBeforeActive ? false : !!stepsAfter,
  });

  if (stepsAfter) {
    if (isShowAfterActive) {
      pushTemp({
        ...updates[activeIndex + 1],
      });
    } else {
      pushTemp({
        text: `${stepsAfter} ${t("more steps")}`,
        ...defaultObj,
      });
    }
  }

  updates = tempUpdates;

  const activeIndexDecider = (updates) => {
    if (isMoreThanFour) {
      const trueActiveIndex = updates.findIndex(
        (update) => !update.isSteps && update !== updates[0]
      );
      return trueActiveIndex;
    }

    return activeIndex;
  };

  const trueActiveIndex = activeIndexDecider(updates);

  return { updates, isMoreThanFour, activeIndex: trueActiveIndex };
};
