import React, { useEffect, useState } from "react";
import { fetch, useMutation } from "../../tools/api";
import { useGetAuth } from "../AuthContext";
import { isEmpty } from "lodash";
import { useMissingReceipt } from "../../pageComponents/taskComponents/logics/hooks";
import { useRouter } from "next/router";
import useUserInvitation from "../../pageComponents/peopleManagementComponents/invitationRequestComponents/hooks";

const contextTemplate = {
  loading: false,
  totalTask: 0,
  data: [],
  is_last_order: [],
  is_not_last_order: [],
  refetch: async () => {},
};

export const TaskContext = React.createContext(contextTemplate);

const TaskProvider = ({ children }) => {
  const [helperLoading, setHelperLoading] = useState(false);
  // const [totalTask, setTotalTask] = useState(0);
  const { user } = useGetAuth();
  const { pathname } = useRouter();

  const { data, loading, refetch } = fetch({
    woInit: true,
    url: "/approval_tasks/index_requested_ids?q[originator_type_not_eq]=WalletTransaction",
    defaultValue: { ...contextTemplate },
    noToaster: true,
    formatter: (res) => {
      const { data, is_last_order, is_not_last_order } = res || {};

      const filterer = (ids) => {
        const filtered = data.filter(({ id }) => (ids || []).includes(id));
        const result = filtered.map((item) => {
          const {
            amount: amountRaw,
            fee: feeRaw,
            id,
            type,
            trx_id,
          } = item || {};
          const isCrossborder = type === "Crossborder Transaction";
          const amount = Number(amountRaw || 0);
          const fee = Number(feeRaw || 0);
          const total = amount + fee;
          return { id, total, isCrossborder, trx_id };
        });
        return result;
      };

      const approvalArray = filterer(is_not_last_order);
      const paymentArray = filterer(is_last_order);
      return { ...res, approvalArray, paymentArray };
    },
    afterSuccess: () => setHelperLoading(false),
  });

  const { totalMissingReceipts, loadingMissingReceipts, seeLess } =
    useMissingReceipt({
      isContext: true,
    });

  const {
    data: invRequests,
    loading: loadingInvRequest,
    refetch: fetchInvitation,
  } = fetch({
    url: "/business_users/invitation_requests?per_page=1",
    woInit: true,
  });
  const { total_data: totalInvRequests = 0 } = invRequests || {};

  const {
    mutation,
    loading: loadingMutation,
    result: eddCount,
    setResult,
  } = useMutation({
    url: "/activity_search",
    method: "post",
    defaultValue: 0,
    // resultFormatter: (res, prev) => transactionsFormatter(res, prev, false),
    resultFormatter: (res) => {
      const count = res?.data?.count;
      return count;
    },
  });

  const payloadMutation = {
    keyword: "",
    filters: {
      page: 1,
      per_page: 1,
      category_not_eq: [
        "local_single",
        "business_billing_statement",
        "allocation_request",
        "return_request",
        "wallet_return",
        "wallet_allocation",
        "wallet_allocation_refund",
        "incoming_payment",
      ],
      status_not_eq: ["draft"],
      category: ["single_cross_border"],
      "details.need_edd": true,
      "details.edd_document_status": "created",
    },
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      setHelperLoading(true);
      const timeout = setTimeout(() => {
        refetch();
        seeLess();
        fetchInvitation();
        mutation(payloadMutation);
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [!isEmpty(user), pathname]);

  const totalTask =
    (data?.data ?? []).length +
    (totalMissingReceipts ?? 0) +
    totalInvRequests +
    (eddCount ?? 0);

  const taskData = {
    ...data,
    loading:
      loading ||
      loadingMissingReceipts ||
      loadingInvRequest ||
      helperLoading ||
      loadingMutation,
    refetch,
    invRequests,
    totalTask,
  };

  const value = {
    ...taskData,
  };

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export default TaskProvider;
