import { useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import CustomHoverInfo from "../../../../../components/CustomHoverInfo/CustomHoverInfo";
import { GothamRegular } from "../../../../../components/Text";
import { Trans, useTranslation } from "react-i18next";

const MissingDetailWarning = ({ customText = "Missing Details" }) => {
  const { t } = useTranslation("category/index");
  const [showHoverInfo, setShowHoverInfo] = useState(false);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setShowHoverInfo(true)}
      onMouseLeave={() => setShowHoverInfo(false)}
    >
      <JackIcons name="alert-triangle" fill={jackColors.yellowFC} />
      <CustomHoverInfo
        show={showHoverInfo}
        rotateTrianglePoint="-90deg"
        translateTrianglePoint="46px 30px"
        style={{
          width: !!customText ? "fit-content" : "100px",
          translate: "-38px -68px",
        }}
      >
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral800, whiteSpace: "nowrap" }}
        >
          {!!customText ? (
            <Trans
              i18nKey={t(customText)}
              components={[
                <span
                  style={{
                    fontFamily: "GothamBold",
                    whiteSpace: "pre-wrap",
                    fontSize: 12,
                    display: "inline-block",
                  }}
                />,
              ]}
            />
          ) : (
            t("Missing Details")
          )}
        </GothamRegular>
      </CustomHoverInfo>
    </div>
  );
};

export default MissingDetailWarning;
