import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../../components/Avatar";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { SelectionJack } from "../../../../../components/inputs/selection";
import {
  AtomicTextFieldJack,
  NumberFieldJack,
  useAutofocus,
} from "../../../../../components/inputs/textfield";
import {
  formatCurrency,
  isProduction,
  unformatCurrency,
  useDebounce,
} from "../../../../../components/tools";
import { useConstants } from "../../../../../contexts/ConstantsContext/parent";
import { apiBusiness, useMutation } from "../../../../../tools/api";
import { TooltipError } from "../../../../PayrollCreateEnhancement/create/tooltip";
import { WrapperModalButtons } from "../../../../homeComponents/topup/components";
import { InputChip } from "../../../../localTransferEnhancement/create/table/inputs/email/chip";
import { confirmIcon } from "../../../../reimbursementComponents/data/constant";
import { useModalHookData } from "../../../../scheduledPaymentComponents/dashboardComponent/hooks";
import { idrStringFormatter } from "../../../data/formatter";
import { CheckBankAnimation, redStarLabel } from "../formEdit";
import { setValueAndRegister } from "../formLogic";
import { recipientResolver } from "./formSchema";
import { extractNumber } from "./modalForm";
import { Trans, useTranslation } from "react-i18next";
import dynamic from "next/dynamic";
import { useLanguage } from "public/locales/translationFunctions";
import { font10 } from "pageComponents/virtualAccountComponents/common/utils";

const RightModal = dynamic(() =>
  import("../../../../../components/Modals/RightModal/parent").then(
    (mod) => mod.RightModal
  )
);
const ConfirmationModalJack = dynamic(() =>
  import("../../../../../components/ButtonsJack/confirmation").then(
    (mod) => mod.ConfirmationModalJack
  )
);
const Banner = dynamic(() =>
  import("../../../../../components/Banner").then((mod) => mod.Banner)
);
const TextFieldJack = dynamic(() =>
  import("../../../../../components/inputs/textfield").then(
    (mod) => mod.TextFieldJack
  )
);

export const LineItemsHeader = ({
  isOpen,
  toggle,
  title,
  icon,
  rightHeaderText,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={toggle}
    >
      <div className="d-flex align-items-center" style={{ height: 40 }}>
        <div className="d-flex align-items-center">
          <JackIcons name={icon} fill="#343434" />
          <GothamMedium style={{ marginLeft: 8 }}>{title}</GothamMedium>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <GothamMedium className="font12">{rightHeaderText}</GothamMedium>
        <JackIcons
          name={isOpen ? "chevron_up" : "chevron_down"}
          fill="#BBBBC0"
          style={{ height: 20, width: 20, marginRight: 8 }}
        />
      </div>
    </div>
  );
};

const TotalPrice = ({ useFormObj, name }) => {
  const { register, control } = useFormObj;

  useEffect(() => {
    register(name);
  }, [register, name]);

  const value = useWatch({ control, name });
  return (
    <div style={{ height: 48, display: "grid", placeItems: "center" }}>
      <GothamMedium>{value}</GothamMedium>
    </div>
  );
};

const LineItemRow = ({
  useFormObj,
  rowData,
  index,
  handleDelete,
  parentName,
  key,
  showRow,
}) => {
  const {
    id,
    itemID,
    item_name,
    price_per_item,
    item_quantity,
    total_price,
    _destroy,
    ...rest
  } = rowData;

  const { t } = useTranslation("invoice-payment/invoice-payment");

  const createName = (childName, index) =>
    `${parentName}.${index}.${childName}`;
  const { setValue, register, getValues, watch, control } = useFormObj;

  //keys
  const itemNameKey = createName("item_name", index);
  const pricePerItemKey = createName("price_per_item", index);
  const itemQuantityKey = createName("item_quantity", index);
  const totalPriceKey = createName("total_price", index);
  const destroyKey = createName("_destroy", index);
  // useAutoFormatRow({ useFormObj, index, parentName });

  const hideRow = watch(destroyKey) || _destroy;

  const handleDeleteRow = (id, index) => {
    if (id) {
      const name = createName("_destroy", index);
      register(name);
      setValue(name, true);
      return;
    }

    return handleDelete(index);
  };

  useEffect(() => {
    if (typeof itemID == "number") {
      const idName = createName("itemID", index);
      register(idName);
      setValue(idName, itemID);
    }
    register(destroyKey);
    setValueAndRegister(setValue, register, pricePerItemKey, price_per_item);
    setValueAndRegister(setValue, register, itemNameKey, item_name);
    setValueAndRegister(setValue, register, itemQuantityKey, item_quantity);
    setValueAndRegister(setValue, register, totalPriceKey, total_price);
    setValueAndRegister(setValue, register, destroyKey, _destroy);
    return;
  }, []);

  const placeholderStyle = useMemo(
    () => ({
      left: -8,
      top: 10,
    }),
    []
  );

  return (
    <tr
      style={{
        height: hideRow || !showRow ? 0 : 48,
        display: hideRow || !showRow ? "none" : "",
        overflow: "hidden",
        backgroundColor: hideRow || !showRow ? "red" : "",
      }}
      key={key}
    >
      <td>
        <TextFieldJack
          useFormObj={useFormObj}
          name={itemNameKey}
          style={{ marginBottom: 0, padding: 0, height: 48 }}
          noBorder
          woLabel
          disableUnderline={false}
          textFieldStyle={{
            padding: "12px 8px",
            border: "",
            height: 48,
          }}
          placeholder={t("Item Name")}
          placeholderStyle={placeholderStyle}
        />
      </td>
      <td>
        <CustomTableField
          useFormObj={useFormObj}
          style={{ marginBottom: 0, padding: 0, height: 48 }}
          name={pricePerItemKey}
          noBorder
          disableUnderline={false}
          textFieldStyle={{
            padding: "12px 8px",
            border: "",
            height: 48,
          }}
          woLabel
          pairName={itemQuantityKey}
          resultName={totalPriceKey}
          placeholder="IDR 0"
        />
      </td>
      <td>
        <QuantityField
          useFormObj={useFormObj}
          name={itemQuantityKey}
          style={{
            marginBottom: 0,
            padding: 0,
            height: 48,
            textAlign: "center",
          }}
          noBorder
          woLabel
          disableUnderline={false}
          textFieldStyle={{
            padding: "12px 8px",
            border: "",
            height: 48,
          }}
          woIDR
          pairName={pricePerItemKey}
          resultName={totalPriceKey}
          placeholder="1"
          textAlign="center"
        />
      </td>
      <td>
        {/* <TextFieldJack
          useFormObj={useFormObj}
          name={createName("total_price", index)}
          style={{ marginBottom: 0, padding: 0, height: 48 }}
          noBorder
          woLabel
          disableUnderline={false}
          textFieldStyle={{
            padding: "12px 8px",
            border: "",
            height: 48,
          }}
          placeholder="IDR 0"
          placeholderStyle={placeholderStyle}
        /> */}
        <TotalPrice useFormObj={useFormObj} name={totalPriceKey} />
      </td>
      <td valign="middle" align="center">
        <JackIcons
          name="delete"
          fill="#BBBBC0"
          style={{ cursor: "pointer" }}
          onClick={() => handleDeleteRow(itemID, index)}
        />
      </td>
    </tr>
  );
};

const CustomTableField = (props) => {
  const { useFormObj, name, autoFocus, required, woIDR, pairName, resultName } =
    props;
  const { register, setValue, watch } = useFormObj;

  const hasValue = !!watch(name);

  const { inputRef } = useAutofocus({
    autoFocus,
    name,
    register,
    required,
  });

  const handleSetResult = (thisValue) => {
    const pairValue = Number(watch(pairName));
    const formatThisValue = woIDR
      ? Number(thisValue)
      : Number(unformatCurrency(thisValue));

    const newResult = "IDR " + formatCurrency(pairValue * formatThisValue);

    return setValue(resultName, newResult);
  };

  const handleOnChange = (e) => {
    const value = e?.target?.value;

    const formattedValue = woIDR
      ? numberOnchange(value)
      : "IDR " + formatCurrency(value);

    !woIDR && setValue(name, formattedValue);

    handleSetResult(formattedValue);
    return;
  };

  const placeholderStyle = useMemo(
    () => ({
      left: -8,
      top: 10,
    }),
    []
  );

  const Input = woIDR ? NumberFieldJack : AtomicTextFieldJack;
  return (
    <AtomicTextFieldJack
      {...props}
      inputRef={inputRef}
      onChange={handleOnChange}
      hasValue={hasValue}
      placeholderStyle={placeholderStyle}
    />
  );
};

export const QuantityField = (props) => {
  const { useFormObj, name, autoFocus, required, woIDR, pairName, resultName } =
    props;
  const { register, setValue, watch } = useFormObj;

  const hasValue = !!watch(name);

  const autoFocusProps = useAutofocus({ name, autoFocus, register, required });

  const handleSetResult = (thisValue) => {
    const pairValue = Number(unformatCurrency(watch(pairName)));
    const formatThisValue = Number(thisValue);

    const newResult = "IDR " + formatCurrency(pairValue * formatThisValue);

    return setValue(resultName, newResult);
  };

  const placeholderStyle = useMemo(
    () => ({
      left: -8,
      top: 10,
    }),
    []
  );

  const quantityVal = watch(name);
  const debounceVal = useDebounce(quantityVal, 1000) ?? "0";

  useEffect(() => {
    if (woIDR) {
      setValue(name, extractNumber(debounceVal));
      handleSetResult(quantityVal);
    }
  }, [debounceVal]);

  return (
    <AtomicTextFieldJack
      {...props}
      {...autoFocusProps}
      // inputRef={inputRef}
      hasValue={hasValue}
      placeholderStyle={placeholderStyle}
    />
  );
};

export const LineItemsTable = ({
  rowCount,
  useFormObj,
  currValue,
  parentName,
  handleDelete,
  shownIndex,
}) => {
  const thStyle = { padding: "12px 8px" };
  const { register } = useFormObj;
  const { setValue } = useFormObj;
  const { t } = useTranslation("invoice-payment/invoice-payment");

  // const handleDeleteRow = (index) => {
  //   const newVal = currValue;
  //   newVal?.splice(index, 1);
  //   console.log(newVal);
  //   setValue(parentName, newVal);
  // };

  const tableWidth = (w) => `${(w / 539) * 100}%`;
  return (
    <table>
      <tr
        style={{
          height: 40,
          borderTop: "1px solid #e6e6e8",
          borderBottom: "1px solid #e6e6e8",
        }}
      >
        <th style={{ ...thStyle, width: tableWidth(150) }}>
          <GothamRegular style={{ color: "#909098" }} className="font12">
            {t("Item")}
          </GothamRegular>
        </th>
        <th style={{ ...thStyle, width: tableWidth(169) }}>
          <GothamRegular style={{ color: "#909098" }} className="font12">
            {t("Price per item")}
          </GothamRegular>
        </th>
        <th style={{ ...thStyle, width: tableWidth(68) }}>
          <GothamRegular style={{ color: "#909098" }} className="font12">
            {t("Quantity")}
          </GothamRegular>
        </th>
        <th style={{ ...thStyle, width: tableWidth(120), textAlign: "center" }}>
          <GothamRegular style={{ color: "#909098" }} className="font12">
            {t("Total Price")}
          </GothamRegular>
        </th>
        <th style={{ ...thStyle, width: tableWidth(32) }}> </th>
      </tr>
      {currValue?.map((rowData, index) => {
        register(`${parentName}.${index}`);
        const showRow = shownIndex?.includes(index);
        return (
          <LineItemRow
            useFormObj={useFormObj}
            rowData={rowData}
            index={index}
            handleDelete={handleDelete}
            parentName={parentName}
            key={rowData?.id}
            showRow={showRow}
          />
        );
      })}
    </table>
  );
};

export const AddButton = ({ onClickAdd, buttonText = "Add item" }) => {
  const { t } = useTranslation("common");
  return (
    <div
      style={{
        padding: 4,
        display: "flex",
        justifyContent: "space-between",
        minWidth: 81,
        width: "auto",
        alignItems: "center",
        borderRadius: 4,
        backgroundColor: "#F1F1F1",
        cursor: "pointer",
        marginLeft: 8,
        gap: 4,
      }}
      onClick={onClickAdd}
    >
      <JackIcons
        name="plus_circle"
        fill="#BBBBC0"
        style={{ width: 16, height: 16 }}
      />
      <GothamRegular className="font12" style={{ color: "#343434" }}>
        {t(buttonText)}
      </GothamRegular>
    </div>
  );
};

export const PrevNextButtons = ({
  onClickNext,
  onClickPrev,
  canNext = false,
  canPrev = false,
}) => {
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const arrowBackColor = canPrev ? "#343434" : "#e6e6e8";
  const arrowNextColor = canNext ? "#343434" : "#e6e6e8";
  const arrowStyle = { width: 18, height: 18 };
  return (
    <div className="d-flex" style={{ gap: 8 }}>
      <div
        style={{
          width: 32,
          height: 32,
          display: "grid",
          placeItems: "center",
          border: "1px solid #e6e6e8",
          borderRadius: 4,
        }}
        className="iconHover"
        onClick={onClickPrev}
      >
        <JackIcons
          name="arrow_back_outline"
          fill={arrowBackColor}
          style={arrowStyle}
        />
      </div>
      <div
        style={{
          width: 32,
          height: 32,
          display: "grid",
          placeItems: "center",
          border: "1px solid #e6e6e8",
          borderRadius: 4,
        }}
        className="iconHover"
        onClick={onClickNext}
      >
        <JackIcons
          name="arrow_forward"
          fill={arrowNextColor}
          style={arrowStyle}
        />
      </div>
    </div>
  );
};

//vendor bank account
export const AddFirstVendorBank = ({ onClick, isError = false }) => {
  const { t } = useTranslation("invoice-payment/invoice-payment");

  return (
    <div
      style={{
        height: 72,
        borderRadius: 4,
        padding: "0px 12px",
        display: "flex",
        gap: 16,
        cursor: "pointer",
        border: `1px solid ${isError ? "#E73126" : "#e6e6e8"}`,
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <Avatar mainIcon={<JackIcons name="account_balance" fill="#fff" />} />
      <div className="d-flex-column">
        <GothamMedium className="font12">
          {t("Input recipient details")}
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          {t("You'll see the details here once they're filled.")}
        </GothamRegular>
      </div>
    </div>
  );
};

export const MultiEmailUseform = ({
  name,
  useFormObj,
  label,
  placeholder,
  defaultValues,
  ...props
}) => {
  const { setValue, register, watch, errors } = useFormObj;
  const currValue = watch(name);
  const isError = errors?.[name];
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const handleChange = ({ chip_val }) => {
    const { values } = chip_val;
    return setValue(name, values);
  };

  useEffect(() => {
    register(name);
  }, []);

  const bottomText = isError
    ? t("Please enter a valid email address")
    : t(
        "Payment receipt will be sent to these email(s). Press 'Enter' after inputting each email."
      );
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <InputChip
        name="chip_val"
        isSingle={false}
        placeholder={placeholder}
        label={label}
        listener={handleChange}
        defaultValues={defaultValues}
        showError={false}
      />
      <GothamRegular
        className="font12"
        style={{
          color: isError ? jackColors.redDC : jackColors.grey90,
          marginBottom: 12,
          position: "relative",
          top: -10,
        }}
      >
        {bottomText}
      </GothamRegular>
    </div>
  );
};

const RecipientForm = ({
  useFormObj,
  defaultValue,
  isNotValid = false,
  children,
}) => {
  const { setValue, setError, errors, reset } = useFormObj;
  const { localTransferBanks } = useConstants();
  const bankPicker = (id) => localTransferBanks.filter((bank) => bank.id == id);
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const { vendor_emails: vendorEmailDefault } = defaultValue || {};
  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      const {
        vendor_emails,
        vendor_name: name,
        local_recipient,
      } = defaultValue;
      const { account_number, payer_id } = local_recipient;
      const [banks] = bankPicker(payer_id);
      setValue("name", name);
      setValue("vendor_emails", vendor_emails);
      setValue("banks", banks);
      setValue("account_number", account_number);
    }
  }, []);

  const selectionError = errors?.["banks"];

  useEffect(() => {
    if (isNotValid) {
      setError("banks", { message: "   " });
      setError("account_number", { message: "   " });
    }
  }, [isNotValid]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 16,
      }}
    >
      <div style={{ marginBottom: 32 }}>
        <GothamMedium className="font24">{t("Recipient Details")}</GothamMedium>
        <GothamRegular style={{ color: "#909098" }}>
          {t("This recipient details can be updated whenever you need it.")}
        </GothamRegular>
      </div>
      <TextFieldJack
        useFormObj={useFormObj}
        name="name"
        label={redStarLabel(t("Vendor Name"))}
        placeholder={t("Enter vendor name")}
        defaultValue={defaultValue?.vendor_name}
      />
      <MultiEmailUseform
        label={t("Email (Optional)")}
        name="vendor_emails"
        useFormObj={useFormObj}
        placeholder={t("Enter email address")}
        defaultValues={vendorEmailDefault ?? []}
      />
      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#E6E6E8",
          marginBottom: 32,
        }}
      />
      <SelectionJack
        useFormObj={useFormObj}
        name="banks"
        label={redStarLabel(t("Bank Name"))}
        placeholder={t("Select bank")}
        options={localTransferBanks}
        containerStyle={selectionError && { border: "1px solid red" }}
        error={selectionError}
        // defaultValue={
        //   validData?.is_valid ? bankPicker(validData?.payer_id)[0] : null
        // }

        // isDisabled={validFlag && !isEdit}
      />
      <TextFieldJack
        useFormObj={useFormObj}
        name="account_number"
        label={redStarLabel(t("Account Number"))}
        placeholder={t("Enter account number")}
        type="number"
        // defaultValue={validData?.account_number ?? null}
        // disabled={validFlag && !isEdit}
      />
      {children}
    </div>
  );
};

const ValidBank = ({ validData }) => {
  const { localTransferBanks } = useConstants();
  const bankPicker = (id) => localTransferBanks.filter((bank) => bank.id == id);
  const alias = bankPicker(validData?.payer_id)?.pop()?.alias;
  return (
    <div
      style={{
        height: 58,
        borderRadius: 4,
        padding: "0px 12px",
        display: "flex",
        gap: 16,
        cursor: "pointer",
        border: "1px solid #e6e6e8",
        alignItems: "center",
      }}
    >
      <Avatar mainIcon={<JackIcons name="account_balance" fill="#fff" />} />
      <div className="d-flex-column">
        <GothamMedium className="font12">
          {validData?.registered_name}
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          {alias} - {validData?.account_number}
        </GothamRegular>
      </div>
    </div>
  );
};

export const AddBankRightModal = ({ isOpen, toggle, afterSuccessAdd }) => {
  const [isNotValid, setIsNotValid] = useState(false);
  const [validData, setValidData] = useState({});
  const isValid = !isEmpty(validData);
  const useFormObj = useForm({ resolver: recipientResolver });
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const { handleSubmit, watch, reset } = useFormObj;

  const verifyUrl = isProduction
    ? "/local_recipients/update_for_validate/26404"
    : "/local_recipients/update_for_validate/26635";

  const { mutation: checkAccount, loading: checkLoading } = useMutation({
    method: "put",
    url: verifyUrl,
    handleError: (err) => console.log(err),
    afterSuccess: ({ data }) => {
      const { data: checkResult } = data;
      const {
        is_valid,
        registered_name,
        account_number,
        payer_id,
        name,
        is_match_name,
      } = checkResult;
      if (!is_valid) setIsNotValid(true);
      if (is_valid) {
        // setIsEdit(false);
        setIsNotValid(false);
        return setValidData({
          is_valid,
          name,
          registered_name,
          account_number,
          payer_id,
          is_match_name,
        });
      }
    },
  });
  const verify = (val) => {
    const { account_number, banks } = val;
    const { id } = banks;
    const verifyData = {
      account_number,
      payer_id: id,
    };

    setIsNotValid(false);
    checkAccount(verifyData);
  };

  const { mutation: createAcc, loading: loadingCreate } = useMutation({
    url: "/recipient_details/create",
    method: "post",
    afterSuccess: ({ data: resData }) => {
      const { data } = resData;
      afterSuccessAdd(data?.id);
      toggle();
    },
  });

  const createRecipient = (val) => {
    const { name, vendor_emails } = val;
    const { registered_name, payer_id, account_number } = validData;
    const local_recipient_attributes = {
      name: registered_name,
      payer_id,
      account_number,
    };

    const payload = {
      local_recipient_attributes,
      vendor_name: name,
      vendor_emails,
    };

    createAcc(payload);
  };

  const rightClickButton = () => {
    // if(validBank) return submit
    if (isValid) return handleSubmit(createRecipient)();

    const banks = watch("banks");
    const account_number = watch("account_number");

    if (Boolean(banks) && account_number?.length > 0)
      return verify({ banks, account_number });

    // return validation
  };

  const bankVal = watch("banks");
  const accNumbVal = watch("account_number");
  const name = watch("name");

  const disableButtonV1 = isEmpty(bankVal) || !accNumbVal?.length > 0;
  const disableButtonV2 = disableButtonV1 || !name;

  useEffect(() => {
    setIsNotValid(false);
    setValidData({});
    reset({});
  }, [isOpen]);
  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      array={[
        <WrapperModalButtons
          childrenButton={
            <div
              style={{
                display: "flex",
                gap: 16,
                width: 380,
              }}
            >
              <ButtonJack
                style={{ width: "50%" }}
                type="outline"
                onClick={toggle}
              >
                {t("Cancel")}
              </ButtonJack>
              <ButtonJack
                style={{ width: "50%" }}
                onClick={rightClickButton}
                disabled={isValid ? disableButtonV2 : disableButtonV1}
              >
                {isValid ? t("Save & Select") : t("Validate")}
              </ButtonJack>
            </div>
          }
        >
          <RecipientForm useFormObj={useFormObj} isNotValid={isNotValid}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {isNotValid && (
                <Banner
                  title={t("Bank account not found.")}
                  msg={t(
                    "Please re-enter and validate the bank account details."
                  )}
                  type="error"
                />
              )}
              {checkLoading && <CheckBankAnimation />}
              {isValid && (
                <>
                  <ValidBank validData={validData} />
                  <GothamRegular
                    style={{
                      ...font10,
                      marginTop: 5,
                      color: jackColors.grey90,
                    }}
                  >
                    {t("Funds will be sent to this account.")}
                  </GothamRegular>
                </>
              )}
            </div>
          </RecipientForm>
        </WrapperModalButtons>,
      ]}
      width={[420]}
      noPadding
      woClose
    />
  );
};
//

export const RightModalButton = ({
  leftText = "Cancel",
  rightText,
  onClickLeft,
  onClickRight,
  isDisabled = false,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 16,
        width: 380,
      }}
    >
      <ButtonJack style={{ width: "50%" }} type="outline" onClick={onClickLeft}>
        {leftText}
      </ButtonJack>
      <ButtonJack
        style={{ width: "50%" }}
        onClick={onClickRight}
        disabled={isDisabled}
      >
        {rightText}
      </ButtonJack>
    </div>
  );
};

export const EditBankRightModal = ({
  isOpen,
  toggle,
  afterSuccessEdit,
  data: defaultValue,
}) => {
  const [isNotValid, setIsNotValid] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [validData, setValidData] = useState({});
  const isValid = !isEmpty(validData);
  const { t } = useTranslation("invoice-payment/invoice-payment");

  const useFormObj = useForm({
    resolver: recipientResolver,
    mode: "all",
    reValidateMode: "all",
  });

  const { reset, handleSubmit } = useFormObj;

  const verifyUrl = isProduction
    ? "/local_recipients/update_for_validate/26404"
    : "/local_recipients/update_for_validate/26635";

  const editAcc = async (val, detailId) => {
    try {
      setIsNotValid(false);
      setCheckLoading(true);
      const { account_number, banks, name, vendor_emails } = val;
      const { id } = banks;
      const verifyData = {
        account_number,
        payer_id: id,
      };

      // setIsNotValid(false);
      const url = `/recipient_details/${detailId}`;
      const { data } = await apiBusiness.put(verifyUrl, verifyData);
      const { data: checkResult } = data;

      const {
        is_valid,
        registered_name,
        account_number: account_number_check,
        payer_id,
      } = checkResult;

      setCheckLoading(false);
      if (!is_valid) {
        setIsNotValid(true);
        return;
      }

      setValidData({ registered_name, account_number });
      const payload = {
        local_recipient_attributes: {
          id: defaultValue?.local_recipient?.id,
          name: registered_name,
          payer_id,
          account_number: account_number_check,
        },
        vendor_name: name,
        vendor_emails,
      };
      const { status } = await apiBusiness.put(url, payload);
      afterSuccessEdit();
    } catch (error) {
      console.log(error);
    }
  };

  const rightClickButton = () => {
    const detailId = defaultValue?.id;
    handleSubmit((val) => editAcc(val, detailId))();
    // return validation
  };

  const handleClose = () => {
    reset();
    toggle();
  };
  const { isOpen: isOpenBackModal, toggle: toggleBackModal } = useModalHook();

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={[
          <WrapperModalButtons
            childrenButton={
              <RightModalButton
                leftText={t("Cancel")}
                rightText={t("Save Changes")}
                onClickLeft={toggleBackModal}
                onClickRight={rightClickButton}
              />
            }
          >
            <RecipientForm
              useFormObj={useFormObj}
              defaultValue={defaultValue}
              isNotValid={isNotValid}
            >
              <div>
                {isNotValid && (
                  <Banner
                    title="Bank account not found"
                    msg="Please re-enter and validate the bank account details."
                    type="error"
                  />
                )}
                <div>
                  {checkLoading && <CheckBankAnimation />}
                  {isValid && <ValidBank validData={validData} />}
                </div>
              </div>
            </RecipientForm>
          </WrapperModalButtons>,
        ]}
        width={[420]}
        noPadding
        woClose
      />
      <ConfirmationModalJack
        img={confirmIcon.tongSampah}
        modal={isOpenBackModal}
        isCentered={true}
        title={t("Are you sure to cancel editing?")}
        text={t("You will lose all changes you’ve made.")}
        buttonTextLeft={t("Back to edit")}
        buttonTextRight={t("Yes, cancel")}
        toggle={toggleBackModal}
        onClick={handleClose}
      />
    </>
  );
};

const recipientDataFormatter = (data) => {
  const { id, vendor_name, vendor_emails, local_recipient, payer_name } = data;

  const bankText = `${payer_name?.toUpperCase()} - ${
    local_recipient?.account_number
  }`;

  const vendorEmailLength = vendor_emails?.length;

  const emailText = () => {
    if (vendorEmailLength == 0) return "";

    return vendorEmailLength == 1
      ? vendor_emails[0]
      : `${vendor_emails[0]}, + ${vendorEmailLength - 1} more`;
  };

  return { vendorName: vendor_name, bankText, emailText: emailText() };
};
//
const VendorOption = ({
  recipient,
  onClick,
  handleEdit,
  handleDelete,
  isSelected,
}) => {
  const { id } = recipient;
  const { vendorName, bankText, emailText } = recipientDataFormatter(recipient);
  return (
    <div
      style={{
        height: 64,
        borderRadius: 4,
        padding: "0px 12px",
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        borderBottom: isSelected ? "" : "1px solid #e6e6e8",
        alignItems: "center",
        backgroundColor: isSelected ? "#e6e6e8" : "#fff",
      }}
      onClick={(e) => {
        e?.stopPropagation();
        onClick(id);
      }}
    >
      <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
        <Avatar name={vendorName} />
        <div className="d-flex-column">
          <GothamMedium className="font12">{vendorName}</GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {bankText}
          </GothamRegular>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {emailText}
          </GothamRegular>
        </div>
      </div>
      <div className="d-flex" style={{ gap: 12 }}>
        <JackIcons
          name="edit_outline"
          fill="#343434"
          onClick={(e) => {
            e?.stopPropagation();
            handleEdit(id);
          }}
        />
        <JackIcons
          name="delete_outline"
          fill="#343434"
          onClick={(e) => {
            e?.stopPropagation();
            handleDelete(recipient);
          }}
        />
      </div>
    </div>
  );
};

const SelectedAccount = ({ selectedData, isActive, onClick }) => {
  const icon = isActive ? "chevron_up" : "chevron_down";
  const { vendorName, bankText, emailText } =
    recipientDataFormatter(selectedData);
  return (
    <div
      style={{
        height: 72,
        borderRadius: 4,
        padding: "0px 12px",
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        border: `1px solid ${isActive ? "#343434" : "#e6e6e8"}`,
        alignItems: "center",
      }}
      onClick={(e) => {
        e?.stopPropagation();
        onClick();
      }}
    >
      <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
        <Avatar name={vendorName} />
        <div className="d-flex-column">
          <GothamMedium className="font12">{vendorName}</GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {bankText}
          </GothamRegular>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {emailText}
          </GothamRegular>
        </div>
      </div>
      <div className="d-flex" style={{ gap: 12 }}>
        <JackIcons
          name={icon}
          fill="#343434"
          onClick={(e) => {
            e?.stopPropagation();
            onClick();
          }}
        />
      </div>
    </div>
  );
};

export const VendorBankSelection = ({
  recipientData,
  selectedId,
  refetchSelections,
  handleSelect,
  isError = false,
}) => {
  const { isOpen, toggle } = useModalHook();
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const { isBahasa: isID } = useLanguage();

  const { isOpen: isOpenAddModal, toggle: toggleAdd } = useModalHook();

  const selectedData = useMemo(() => {
    return recipientData?.filter(({ id }) => id == selectedId)?.pop();
  }, [selectedId, recipientData]);

  const noSelectedData = isEmpty(selectedData);

  const handleDelete = async (id) => {
    try {
      const res = await apiBusiness.delete(`/recipient_details/${id}`);
      refetchSelections();
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: editData,
    isOpen: isOpenEdit,
    close: closeEdit,
    open: openEditModal,
  } = useModalHookData();

  const {
    data: deleteData,
    isOpen: isOpenDelete,
    close: closeDelete,
    open: openDelete,
  } = useModalHookData();

  const title = t("Input recipient details");
  const text = t("You'll see the details here once they're filled.");
  return (
    <div style={{ position: "relative" }}>
      {noSelectedData ? (
        <div
          style={{
            height: 72,
            borderRadius: 4,
            padding: "0px 12px",
            display: "flex",
            gap: 16,
            cursor: "pointer",
            border: `1px solid ${isError ? "#E73126" : "#e6e6e8"}`,
            alignItems: "center",
            position: "relative",
          }}
          onClick={toggle}
        >
          <Avatar mainIcon={<JackIcons name="account_balance" fill="#fff" />} />
          <div className="d-flex-column">
            <GothamMedium className="font12">{title}</GothamMedium>
            <GothamRegular className="font12" style={{ color: "#909098" }}>
              {text}
            </GothamRegular>
          </div>
          {recipientData?.length > 0 && (
            <JackIcons
              name={!isOpen ? "chevron_down" : "chevron_up"}
              fill="#343434"
              style={{ width: 20, height: 20, position: "absolute", right: 16 }}
            />
          )}
        </div>
      ) : (
        <SelectedAccount
          isActive={isOpen}
          onClick={toggle}
          selectedData={selectedData}
        />
      )}
      {isOpen && (
        <div
          style={{
            padding: 12,
            width: "100%",
            height: "auto",
            position: "absolute",
            borderRadius: 4,
            border: "1px solid #E6E6E8",
            backgroundColor: "#FFF",
            boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
            top: 80,
            display: "flex",
            flexDirection: "column",
            gap: 12,
            zIndex: 100,
          }}
        >
          <div style={{ maxHeight: 64 * 3, height: "auto", overflowY: "auto" }}>
            {recipientData?.map((data) => {
              const isSelected = data?.id == selectedId;
              return (
                <VendorOption
                  recipient={data}
                  handleDelete={openDelete}
                  handleEdit={() => openEditModal(data)}
                  onClick={(id) => {
                    handleSelect(id);
                    toggle();
                  }}
                  isSelected={isSelected}
                />
              );
            })}
          </div>
          <ButtonJack
            type="outline"
            style={{ width: "100%" }}
            onClick={toggleAdd}
            leftIcon={<JackIcons name="plus" fill="#343434" />}
          >
            {t("Add new recipient")}
          </ButtonJack>
        </div>
      )}
      <AddBankRightModal
        isOpen={isOpenAddModal}
        toggle={toggleAdd}
        afterSuccessAdd={(id) => {
          handleSelect(id);
          refetchSelections();
        }}
      />
      <EditBankRightModal
        isOpen={isOpenEdit}
        toggle={closeEdit}
        data={editData}
        afterSuccessEdit={refetchSelections}
      />
      <ConfirmationModalJack
        img={confirmIcon.tongSampah}
        modal={isOpenDelete}
        isCentered={true}
        title={t("Delete recipient?")}
        text={
          isID
            ? `Anda akan menghapus ${deleteData?.vendor_name} dari daftar penerima.`
            : `You will remove ${deleteData?.vendor_name} from your recipient list.`
        }
        buttonTextLeft={t("Back")}
        buttonTextRight={t("Delete")}
        toggle={closeDelete}
        onClick={() => handleDelete(deleteData?.id)}
      />
    </div>
  );
};

const DefaultTitle = () => {
  const { isBahasa: isID } = useLanguage();
  return <GothamMedium>{isID ? "Ringkasan" : "Summary"}</GothamMedium>;
};

export const idrFormatterNegative = (number) => {
  if (number < 0)
    return (
      <div className="d-flex" style={{ gap: 4 }}>
        <JackIcons
          name={"minus"}
          fill={"#DC2F44"}
          style={{ width: 12, height: 12 }}
        />{" "}
        <>{idrStringFormatter(number * -1)}</>
      </div>
    );

  return (
    <div className="d-flex" style={{ gap: 4 }}>
      <JackIcons
        name={"plus"}
        fill={"#238730"}
        style={{ width: 12, height: 12 }}
      />{" "}
      {idrStringFormatter(number)}
    </div>
  );
};

export const InvoiceFormSummary = ({
  data,
  rightHeaderComponent = null,
  customHeader = null,
  title,
  isCustomTitle = false,
  isReimbursement = false,
  hideFee = false,
}) => {
  const {
    item_subtotal,
    tax_total,
    amount,
    fee,
    finalAmount,
    transaction_items,
    transaction_taxes,
    isMissmatch,
  } = data || {};
  const { isOpen: isOpenSubtotal, toggle: toggleSubtotal } = useModalHook();
  const { isOpen: isOpenTax, toggle: toggleTax } = useModalHook();
  const { t } = useTranslation("invoice-payment/invoice-payment");

  return (
    <div
      style={{
        padding: "0px 12px 12px",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        width: "100%",
        height: "auto",
        borderRadius: 4,
        border: "1px solid #e6e6e8",
      }}
    >
      {customHeader ?? (
        <div
          style={{
            width: "100%",
            height: 40,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #e6e6e8",
          }}
        >
          {!isCustomTitle ? <DefaultTitle /> : title}
          {rightHeaderComponent}
        </div>
      )}
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: 4 }}>
            <JackIcons
              name="shopping_cart_outline"
              fill="#BBBBC0"
              style={{ height: 16, width: 16 }}
            />
            <GothamRegular style={{ color: "#909098" }}>
              {t("Items subtotal")}
            </GothamRegular>
            <JackIcons
              name={isOpenSubtotal ? "chevron_up" : "chevron_down"}
              fill="#BBBBC0"
              style={{ height: 16, width: 16, cursor: "pointer" }}
              onClick={toggleSubtotal}
            />
          </div>
          <div className="d-flex" style={{ gap: 8, alignItems: "center" }}>
            <MismatchSubtotalToolTip isMissmatch={isMissmatch} />{" "}
            <GothamRegular>{idrStringFormatter(item_subtotal)}</GothamRegular>
          </div>
        </div>
        {isOpenSubtotal && (
          <div
            style={{
              marginLeft: 6,
              borderLeft: "4px solid #e6e6e8",
              marginTop: 8,
            }}
          >
            <div
              style={{
                paddingLeft: 11,
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {transaction_items?.map(
                ({ item_name, item_quantity, price_per_item, total_price }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        height: 32,
                      }}
                    >
                      <div
                        className="d-flex-column justify-content-between"
                        style={{ width: "60%" }}
                      >
                        <GothamRegular
                          className="font12"
                          style={{
                            color: "#909098",
                            width: "100%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item_name}
                        </GothamRegular>
                        <GothamRegular
                          className="font12"
                          style={{ color: "#909098" }}
                        >
                          ({item_quantity} x{" "}
                          {idrStringFormatter(price_per_item)})
                        </GothamRegular>
                      </div>
                      <GothamRegular
                        className="font12"
                        style={{ color: "#909098" }}
                      >
                        {idrStringFormatter(total_price)}
                      </GothamRegular>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: 4 }}>
            <JackIcons
              name="discount"
              fill="#BBBBC0"
              style={{ height: 16, width: 16 }}
            />
            <GothamRegular style={{ color: "#909098" }}>
              {t("Taxes & Other Fees")}
            </GothamRegular>
            <JackIcons
              name={isOpenTax ? "chevron_up" : "chevron_down"}
              fill="#BBBBC0"
              style={{ height: 16, width: 16, cursor: "pointer" }}
              onClick={toggleTax}
            />
          </div>
          <GothamRegular>{idrFormatterNegative(tax_total)}</GothamRegular>
        </div>
        {isOpenTax && (
          <div
            style={{
              marginLeft: 6,
              borderLeft: "4px solid #e6e6e8",
              marginTop: 8,
            }}
          >
            <div
              style={{
                paddingLeft: 11,
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {transaction_taxes?.map(
                ({ fee_tax_name, tax_description, tax_value }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        height: 32,
                      }}
                    >
                      <div className="d-flex-column justify-content-between">
                        <GothamRegular
                          className="font12"
                          style={{ color: "#909098" }}
                        >
                          {fee_tax_name}
                        </GothamRegular>
                        <GothamRegular
                          className="font12"
                          style={{ color: "#909098" }}
                        >
                          {tax_description}
                        </GothamRegular>
                      </div>
                      <GothamRegular
                        className="font12"
                        style={{ color: "#909098" }}
                      >
                        {idrFormatterNegative(tax_value)}
                      </GothamRegular>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
      {!(isReimbursement || hideFee) && (
        <>
          <div
            style={{
              width: "100%",
              height: 1,
              borderTop: "1px dashed #e6e6e8",
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <GothamRegular>{t("Invoice amount")}</GothamRegular>
            <GothamMedium>{idrStringFormatter(amount)}</GothamMedium>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="d-flex">
              <JackIcons
                style={{ width: 16, height: 16, marginRight: 4 }}
                name="plus_circle"
                fill="#BBBBC0"
              />
              <GothamRegular>{t("Handling fee")}</GothamRegular>
            </div>
            <GothamRegular>{idrStringFormatter(fee)}</GothamRegular>
          </div>
        </>
      )}
      <div
        style={{
          width: "100%",
          height: 1,
          borderTop: "1px dashed #e6e6e8",
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <GothamRegular>{t("Amount")}</GothamRegular>
        <GothamMedium>
          {idrStringFormatter(hideFee ? amount : finalAmount)}
        </GothamMedium>
      </div>
    </div>
  );
};

export const MismatchSubtotalToolTip = ({ isMissmatch = false }) => {
  if (!isMissmatch) return null;
  const { t } = useTranslation("invoice-payment/invoice-payment");

  return (
    <div className="d-flex" style={{ gap: 4, alignItems: "center" }}>
      <TooltipError
        desc={
          <div style={{}}>
            <GothamRegular
              className="font12"
              style={{ color: "#6c6c71", textAlign: "center" }}
            >
              <Trans i18nKey={t("dynamic.tooltip")} components={[<br />]} />
            </GothamRegular>
          </div>
        }
      >
        <div style={{ width: 18, height: 18 }}>
          <JackIcons
            name="alert_circle_outline"
            style={{ width: 18, height: 18 }}
            fill="#909098"
          />
        </div>
      </TooltipError>
    </div>
  );
};

export const MismatchSubtotalToolTipV2 = ({ isMissmatch = false }) => {
  if (!isMissmatch) return null;
  const { t } = useTranslation("invoice-payment/invoice-payment");
  const { isBahasa: isID } = useLanguage();

  return (
    <div className="d-flex" style={{ gap: 4, alignItems: "center" }}>
      <TooltipError
        title={
          <GothamMedium style={{ color: "#343434" }}>
            {t("Subtotal doesn't match item details")}
          </GothamMedium>
        }
        desc={
          <div style={{}}>
            <GothamRegular
              className="font12"
              style={{ color: "#6c6c71", textAlign: "center" }}
            >
              {isID
                ? "Anda boleh sesuaikan atau abaikan saja."
                : `Feel free to match the amount or submit it ${(
                    <br />
                  )}right away.`}
            </GothamRegular>
          </div>
        }
      >
        <div style={{ width: 18, height: 18 }}>
          <JackIcons
            name="alert_circle_outline"
            style={{ width: 18, height: 18 }}
            fill="#909098"
          />
        </div>
      </TooltipError>
    </div>
  );
};

export const DuplicateToolTip = ({ isDuplicate = false }) => {
  const { isBahasa: isID } = useLanguage();
  if (!isDuplicate) return null;

  return (
    <div className="d-flex" style={{ gap: 4, alignItems: "center" }}>
      <TooltipError
        title={isID ? "Invoice terduplikat" : "Duplicated Invoice"}
        desc={
          <div style={{}}>
            <GothamRegular
              className="font12"
              style={{ color: "#6c6c71", textAlign: "start" }}
            >
              {isID
                ? "Sebelum mengajukan, pastikan semua rincian invoice sudah terisi dan sesuai."
                : "You’ve decided to keep this invoice. The approver will be notified accordingly."}
            </GothamRegular>
          </div>
        }
      >
        <div style={{ width: 18, height: 18 }}>
          <JackIcons
            name="alert_circle_outline"
            style={{ width: 18, height: 18 }}
            fill="#FCB037"
          />
        </div>
      </TooltipError>
    </div>
  );
};
