import { noCase } from "change-case";
import { get, isEmpty, pickBy } from "lodash";
import { removeDuplicates } from "components/tools";
import {
  VAPathnames,
  accountPathnames,
  activitiesORTransactionsPathnames,
  canBeAccessedAllTheTimePathnames,
  createTransactionPathnames,
} from "constants/pathnames";
import { booleanState } from "components/Status";
import { MyAccount } from "contexts/AuthContext";

export const userFormatter = (user: MyAccount): MyAccount => {
  const {
    all_permissions = [],
    business_role = "",
    // pin: pinOld = "",
    registered_pin,
    roles = [],
    email,
    id,
    subscription,
  } = user || {};

  const pin = registered_pin ? "ada kok" : "";

  const isAdminStrict = Boolean(
    roles.filter(({ name }) => {
      const result = ["partner_admin", "partner_super_admin"].includes(
        name ?? ""
      );
      return result;
    }).length
  );

  const isWorkflowEditor = !isEmpty(
    roles.filter(({ name }) => (name ?? "").includes("editor"))
  );

  const filterer = (roleName: string) =>
    Boolean(roles.filter(({ name }) => (name ?? "").includes(roleName)).length);

  const isBookKeeper = filterer("viewer");

  // const isPayrollApprover = filterer("payroll_approver");

  const isDrafterCard = filterer("card_drafter");

  const isDrafterSendMoney = filterer("sendmoney_drafter");
  const isDrafterPayroll = filterer("payroll_drafter");
  const isDrafterInvoice = filterer("invoice_drafter");
  const isDrafterReimbursement = filterer("reimbursement_drafter");
  const isDrafterJackTransfer = filterer("balance_transfer_drafter");

  const {
    payroll_active,
    hide_beta_feature = [],
    all_flags = [],
    card_business,
    id: partner_id,
  } = user?.partner || {};

  const { card_business_types } = card_business || {};
  const virtualCard = card_business_types?.find(
    (e) => e.card_type === "virtual_prepaid"
  );
  const { state } = virtualCard || {};

  const isFinishedOnBoarding = state && state !== "draft";

  const permissionsArray = all_permissions
    .map(({ all_action_types, module_code }) => {
      const array = (all_action_types ?? []).map((item) => ({
        ...item,
        module_code,
      }));
      return array;
    })
    .flat(1);

  let buttonBooleans: { [index: string]: boolean } = {};

  const isDurianPayDecider = () => {
    if (!(email ?? "").includes("@durianpay")) return false;
    if (partner_id == 76) return false;
    return true;
  };

  const isDurianPay = isDurianPayDecider();
  const isAPIOnly = (email ?? "").includes("sandboxdemo@disbursement.qa.team");

  const canViewCards =
    !all_flags.includes("SIGN_IN_SUCCESS") &&
    (isAdminStrict || isFinishedOnBoarding);

  const canViewAllCards = canViewCards && true;

  const canCreateCard = isFinishedOnBoarding && canViewCards && isDrafterCard;

  const canOnboardCard = canViewCards && isAdminStrict;

  const canCreatePin = !pin?.length;
  const canViewReport = isBookKeeper || isAdminStrict;
  const canViewEWallet = all_flags.includes("E_WALLET_ACTIVE") || isAdminStrict;
  const canViewApiDisbursement = all_flags.includes(
    "ACTIVITY_API_DISBURSEMENT"
  );
  const canViewInvoicePayment = isDrafterInvoice;
  const canViewReimbursement = isDrafterReimbursement;
  const canViewPlans = isAdminStrict;
  const canViewJackTransfer = isDrafterJackTransfer;
  const canViewDevTools = false;

  // const canViewWorkflow = isAdminStrict && isWorkflowEditor;
  const canViewWorkflow = true;

  const canSeeVA = isAdminStrict || isBookKeeper;
  const canCreateVA = isAdminStrict || isBookKeeper; // original: admin only
  // const isUltimate = true;

  const checkerOnlyModuleCode = ({
    module_code_params,
  }: {
    module_code_params?: string;
  }) =>
    !!all_permissions.filter(
      ({ module_code }) => module_code === module_code_params
    )[0];

  const canCustomReceipt = checkerOnlyModuleCode({
    module_code_params: "CUSTOM_PREFERENCE",
  });

  const dynamicPathnames = permissionsArray
    .map(({ module_code, action, model_name }) => {
      // NOTES: this code has duality function.
      // 1. builds buttonBooleans so it can be used anywhere
      // 2. returns pathnames so it can check if user is in the right path or not
      // so for e.g you want to change `canViewPayroll`
      // and you do it like this: `const canViewPayroll = isAdmin`
      // the result is if you are the admin, you can ONLY ACCESS `/payroll` and cant go anywhere coz u stopped the logic tree
      // BUT if you can somehow turn it to FALSE or MODULE it will work just fine, cause the tree logic will keep running

      // In a nutshell: Please just use MODULES (booleanChecker) in this code OR just return false e.g: `canViewActivityFunc`

      const booleanChecker = (
        module_code_params: string,
        action_params: string,
        model_name_params: string
      ) =>
        noCase(module_code ?? "") == noCase(module_code_params) &&
        noCase(action ?? "") == noCase(action_params) &&
        noCase(model_name ?? "") == noCase(model_name_params);

      const canViewActivityFunc = () => {
        // if (isPayrollApprover && !isAdminStrict) return false;

        return (
          booleanChecker("ACTIVITY", "view_all_activities", "Job") ||
          booleanChecker("ACTIVITY", "view_transactions", "BusinessTransaction")
        );
      };

      const canViewActivity = canViewActivityFunc();

      const canSendMoney = isDrafterSendMoney;
      const canViewRecipients = booleanChecker(
        "SEND_MONEY",
        "view_recipients",
        "UserRecipient"
      );

      // booleanChecker("COLLECT_MONEY", "view_va", "User");

      const canSeeUsers = booleanChecker("USER_MANAGEMENT", "update", "User");
      const canInviteUser = booleanChecker("USER_MANAGEMENT", "invite", "User");
      const canViewAccount = booleanChecker(
        "WALLET_BALANCE",
        "view_balance",
        "User"
      );

      // const canCreateCard = booleanChecker("CARD", "issue_card", "UserCard");
      const canUpdateCard = booleanChecker("CARD", "assign_limit", "UserCard");
      const canBlockCard = booleanChecker("CARD", "block", "UserCard");
      const canFreezeCard = booleanChecker("CARD", "freeze", "UserCard");
      const canUnfreezeCard = booleanChecker("CARD", "unblock", "UserCard");
      const canUnmaskCard =
        booleanChecker("CARD", "unmasked_card", "UserCard") ||
        booleanChecker("CARD", "unmask_details", "UserCard");
      const canViewCardsExpenses = booleanChecker(
        "CARD",
        "view_expenses",
        "UserCard"
      );

      // const isAdmin =
      //   isAdminStrict || String(business_role).includes("approver");

      const canViewMainBalance =
        (isAdminStrict || isBookKeeper) &&
        booleanChecker("WALLET_BALANCE", "view_balance", "User");

      const canTopup = booleanChecker(
        "WALLET_BALANCE",
        "topup",
        "TransactionActivity"
      );
      const canWithdraw = booleanChecker(
        "WALLET_BALANCE",
        "topup",
        "TransactionActivity"
      );
      const canAllocate = booleanChecker(
        "WALLET_BALANCE",
        "allocate_fund",
        "TransactionActivity"
      );
      const canViewMainBalanceHistory =
        isAdminStrict &&
        booleanChecker(
          "WALLET_BALANCE",
          "balance_history",
          "TransactionActivity"
        );
      const canViewCardBalance = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "view_balance",
        "User"
      );
      const canReallocate = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "reallocate",
        "TransactionActivity"
      );
      const canViewCardBalanceHistory = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "balance_history",
        "TransactionActivity"
      );

      const viewPayrollDecider = () => {
        const canViewPayroll = booleanChecker(
          "PAYROLL",
          "view_payroll",
          "Payroll"
        );

        if (isAdminStrict || payroll_active) return canViewPayroll;
        return false;
      };

      const canShowPayroll = !(hide_beta_feature ?? []).includes("payroll");
      const canViewPayroll = canShowPayroll && viewPayrollDecider();

      const canApprovePayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "approve_payroll", "Payroll");
      const canRejectPayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "reject_payroll", "Payroll");
      const canCancelPayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "cancel_payroll", "Payroll");
      const canCreatePayroll = canShowPayroll && isDrafterPayroll;

      // buttonsPreparer
      const objectBooleans = {
        canViewAccount,
        canViewActivity,
        canSendMoney,
        canViewRecipients,
        canSeeVA,
        canCreateVA,
        canSeeUsers,
        canInviteUser,
        canCreatePin,
        //cards
        canViewCardsExpenses,
        canCreateCard,
        canUpdateCard,
        canBlockCard,
        canFreezeCard,
        canUnfreezeCard,
        canUnmaskCard,
        canViewCards,
        canViewAllCards,
        //cards
        // home props
        canViewReport,
        canViewWorkflow,
        canViewMainBalance,
        canTopup,
        canWithdraw,
        canAllocate,
        canViewMainBalanceHistory,
        canViewCardBalance,
        canReallocate,
        canViewCardBalanceHistory,
        // home props
        // payrolls
        canViewPayroll,
        canCreatePayroll,
        canApprovePayroll,
        canRejectPayroll,
        canCancelPayroll,
        // payrolls
        //e-wallet
        canViewEWallet,
        //e-wallet
        canViewInvoicePayment,
        canViewReimbursement,
        canViewApiDisbursement,
        canViewSettings: true,
        canViewHistory: true,
        canViewTasks: true,
        canCustomReceipt,
      };

      const keys = Object.keys(objectBooleans);

      keys.map((key) => {
        const value = objectBooleans[
          key as keyof typeof objectBooleans
        ] as boolean;
        if (!value) return;
        buttonBooleans[key as keyof typeof buttonBooleans] = value;
      });

      // strict urls
      // payroll
      if (canViewPayroll) return ["/payroll"];
      if (canCreatePayroll) return ["/payroll/create"];
      if (canCreatePayroll && payroll_active) return ["/payroll/invite"];
      // payroll

      if (canUpdateCard) return ["/cards/[id]/edit"];
      // strict urls

      // buttonsPreparer
      if (canViewAccount) return accountPathnames;
      if (canViewActivity) return activitiesORTransactionsPathnames;
    })
    .filter((val) => val)
    .flat(1);

  const canViewLocalTransfer = !hide_beta_feature.includes("local_transfer");

  const createPin = canCreatePin ? ["/account/create-pin"] : [];
  const viewReport = canViewReport ? ["/report-download"] : [];
  const eWallet = canViewEWallet ? ["/e-wallet"] : [];
  const workflow = canViewWorkflow ? ["/workflow"] : [];
  const invoicePayment = canViewInvoicePayment ? ["/invoice-payment"] : [];
  const apiDisbursement = canViewApiDisbursement ? ["/histories-api"] : [];
  const reimbursement = canViewReimbursement ? ["/reimbursement"] : [];
  const plans = canViewPlans ? ["/plans"] : [];
  const jackTransfer = canViewJackTransfer ? ["/jack-transfer"] : [];

  const viewCard = canViewCards ? ["/cards"] : [];
  const createCard = canCreateCard ? ["/cards/create"] : [];
  const initiateCard = canOnboardCard ? ["/cards/onboard"] : [];
  const customReceipt = canCustomReceipt ? ["/customize-receipt"] : [];
  const { isUltimate } = subscription || {};
  const ultimate = isUltimate
    ? ["/move-balance", "/va-pocket", "/primary-bank-account"]
    : [];
  const devTools = canViewDevTools ? ["/dev-tools"] : [];

  const va = canSeeVA ? VAPathnames : [];
  const createVA = canCreateVA ? ["/virtual-account/create"] : [];
  const sendMoney = isDrafterSendMoney ? createTransactionPathnames : [];

  const pathnamesDecider = () => {
    const activePathnames: string[] = removeDuplicates([
      ...createVA,
      ...va,
      ...sendMoney,
      ...canBeAccessedAllTheTimePathnames,
      ...dynamicPathnames,
      ...createPin,
      ...viewReport,
      ...eWallet,
      ...workflow,
      ...invoicePayment,
      ...apiDisbursement,
      ...reimbursement,
      ...viewCard,
      ...createCard,
      ...initiateCard,
      ...customReceipt,
      ...plans,
      ...jackTransfer,
      ...ultimate,
      ...devTools,
    ]);
    buttonBooleans = { ...buttonBooleans, canViewLocalTransfer };

    const durianPayHiddenPathnames = [
      "/cards",
      "/e-wallet",
      "/payroll",
      "/invoice-payment",
      "/users",
      "/users/edit-workflow",
      "/reimbursement",
      "/cards/create",
    ];

    const defaultHiddenPathnames = [...durianPayHiddenPathnames, "/workflow"];

    const durianPayHiddenBooleans = [
      "canAllocate",
      "canApprovePayroll",
      "canCancelPayroll",
      "canInviteUser",
      "canRejectPayroll",
      "canSeeUsers",
      "canViewPayroll",
      "canViewCards",
      "canViewEWallet",
      "canViewInvoicePayment",
      "canViewAllCards",
      "canViewReimbursement",
    ];

    const defaultHiddenButtonBooleans = [
      ...durianPayHiddenBooleans,
      "canViewWorkflow",
    ];

    if (isDurianPay) {
      buttonBooleans = pickBy(
        buttonBooleans,
        (_, key) =>
          ![
            ...durianPayHiddenBooleans,
            "canViewTasks",
            "canViewHistory",
            "canSendMoney",
          ].includes(key)
      );
      const filteredPathnames = activePathnames.filter(
        (key) => !durianPayHiddenPathnames.includes(key)
      );

      return filteredPathnames;
    }

    if (isAPIOnly) {
      const hiddenPathnames = [
        ...defaultHiddenPathnames,
        "/menu-api",
        "/local-transfer",
        "/local-disbursement",
        "/settings",
      ];
      const hiddenButtonBooleans = [
        ...defaultHiddenButtonBooleans,
        "canViewDevTools",
        "canViewSettings",
        "canSendMoney",
        "canViewHistory",
        "canViewTasks",
      ];
      buttonBooleans = {
        ...pickBy(
          buttonBooleans,
          (_, key) => !hiddenButtonBooleans.includes(key)
        ),
        canViewApiDisbursement: true,
      };
      const filteredPathnames = [
        ...activePathnames.filter((key) => !hiddenPathnames.includes(key)),
        "/histories-api",
      ];
      return filteredPathnames;
    }

    return activePathnames;
  };

  const activePathnames = pathnamesDecider();

  // customize user props
  const globalRoles = get(user, "roles", []).filter((role) => {
    const roleName = get(role, "name", "");
    const beOnlyRoles = ["admin", "checker", "maker"];
    return !beOnlyRoles.includes(roleName);
  });

  const role = globalRoles[0]?.name || "";
  const isPunyUser = ["partner_viewer", "partner_card_user"].includes(role);
  const isAdmin = !isPunyUser;
  const mainBalance = (user?.partner?.disbursement_balance || [])[0] || {};

  const { partner } = user || {};
  const {
    document_state: document_stateRaw,
    director_basis_id,
    business_type,
  } = partner || {};

  const isIndividual = business_type == "individual";

  const { isWaitingVerification } = booleanState(document_stateRaw);

  const document_state =
    isWaitingVerification && !director_basis_id && !isIndividual
      ? "not_submitted"
      : document_stateRaw;
  // customize user props

  const logoutTime = new Date().getTime() + 55 * 60 * 1000; // in 55 min, auto kick
  return {
    isDurianPay,
    isAPIOnly,
    activePathnames,
    buttonBooleans,
    role,
    isAdmin,
    mainBalance,
    logoutTime,
    ...user,
    pin,
    partner: {
      ...partner,
      // document_state,
      real_document_state: document_stateRaw,
    },
  };
};
